export interface ILoggedUser {
    id: string;
    fullName: string;
    job_title: string;
    email: string;
    type: number | undefined;
    nurse_id: string;
    floors: string[];
    imageURL: string;
}

export const emptyLoggedUser: ILoggedUser = {
    id: "",
    fullName: "",
    job_title: "",
    email: "",
    type: undefined,
    nurse_id: "",
    floors: [],
    imageURL: "",
};

export interface ISelectedPatient {
    // In DB, attentionId is patientid.
    id: string;
    name: string;
    dateAdmission: string;
    dateEgress: string | null;
    room: string | null;
    categoryId: string | null;
    categoryName: string | null;
    categoryMaxValue: string | null;
    floorId: string | null;
    floorName: string | null;
    nurseHistory: string[] | null;
    nurseId: string | null;
    nurseName: string | null;
    attentionId: string | null;
    secondaryNurseId: string | null;
    secondaryNurseName: string | null;
    specialityId: string | null;
    specialityName: string | null;
}

export let emptySelectedPatient: ISelectedPatient = {
    id: "",
    name: "",
    dateAdmission: "",
    dateEgress: null,
    room: null,
    categoryId: null,
    categoryName: null,
    categoryMaxValue: null,
    floorId: null,
    floorName: null,
    nurseHistory: null,
    nurseId: null,
    nurseName: null,
    attentionId: null,
    secondaryNurseId: null,
    secondaryNurseName: null,
    specialityId: null,
    specialityName: null,
};
