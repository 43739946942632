import { OptionCategory, OptionRange } from "../../interfaces/OptionCategory";
import { ISelectedPatient } from "../../store/interfaces";
import { URI_API, getHeaders } from "../../utils/config";
import { Box, Modal, Switch } from "@mui/material";
import {
    AppState,
    deleteCategoryList,
    deletePatientCategoryId,
    setCategoryList,
    setPatientCategoryId,
} from "../../store/reducer";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { Dispatch } from "redux";

export default function ModalCategory(props: {
    open: boolean;
    onClose: Function;
    onSave: Function;
}) {
    const [categoryInformation, setCategoryInformation] = useState<
        OptionCategory[]
    >([]);
    const [categoryRange, setCategoryRange] = useState<OptionRange[]>([]);

    const [count, setCount] = useState<number>(0);
    const [selectedOptionsCategory, setSelectedOptionsCategory] = useState<
        string[]
    >([]);

    // Patient from Redux
    const selectedPatient: ISelectedPatient = useSelector(
        (state: AppState) => state.selectedPatient
    );
    const currentCategoryList = useSelector(
        (state: AppState) => state.categoryList
    );

    const dispatch: Dispatch<any> = useDispatch();

    const getCategoryInformation = async () => {
        // Get Category Information.
        let categories_res = await (
            await fetch(
                `${URI_API}/checklist/patient_categorization`,
                getHeaders()
            )
        ).json();
        const categories = categories_res.map((category: any) => {
            return {
                id: category.id,
                category: category.category,
                subcategory: category.subcategory,
                description: category.description,
                value: category.value,
                checked: false,
            };
        });
        setCategoryInformation(categories);
        dispatch(setCategoryList(categories));

        let categories_ranges_res = await (
            await fetch(
                `${URI_API}/class_ranges/patient_categorization`,
                getHeaders()
            )
        ).json();
        setCategoryRange(
            categories_ranges_res
                .map((category_range: any) => {
                    return {
                        id: category_range.id,
                        description: category_range.description,
                        category: category_range.category,
                        min: parseFloat(category_range.minimumvalue),
                        max: parseFloat(category_range.maximumvalue),
                    };
                })
                .sort((a: OptionRange, b: OptionRange) => a.min - b.min)
        );
    };

    useEffect(() => {
        getCategoryInformation();
    }, []);

    const getCategoryLabel = (): string => {
        let label = "";

        categoryRange.forEach((category_range: OptionRange) => {
            if (count >= category_range.min && count <= category_range.max) {
                label = category_range.description;
            }
        });

        return label;
    };

    const toggleCategoryItem = (
        itemId: string,
        value: number,
        checked: boolean
    ) => {
        setCount(checked ? count + value : count - value);

        setSelectedOptionsCategory(
            checked
                ? [...selectedOptionsCategory, itemId]
                : selectedOptionsCategory.filter((selectedOption: string) => {
                      return selectedOption !== itemId;
                  })
        );

        let categoryList = [...currentCategoryList];
        const index = categoryList.findIndex((item: any) => item.id === itemId);
        const currentItem = categoryList[index];
        categoryList[index] = {
            category: currentItem.category,
            subcategory: currentItem.subcategory,
            checked: checked,
            description: currentItem.description,
            id: currentItem.id,
            value: currentItem.value,
            title: "",
        };
        dispatch(setCategoryList(categoryList));
    };

    const buildOptions = () => {
        if (categoryInformation === undefined) return <></>;

        return categoryInformation.map(
            (category_information: OptionCategory) => {
                return (
                    <div className="custom-modal__body__table__options">
                        <div style={{ borderBottom: "1px solid #DEDEDE" }}>
                            <h4>{category_information.description}</h4>
                            <Switch
                                checked={selectedOptionsCategory.includes(
                                    category_information.id
                                )}
                                onChange={(_, checked) => {
                                    toggleCategoryItem(
                                        category_information.id,
                                        category_information.value,
                                        checked
                                    );
                                }}
                                color="secondary"
                            ></Switch>
                        </div>
                    </div>
                );
            }
        );
    };

    const getCategoryId = (): string => {
        let id = "";
        categoryRange.map((range: OptionRange) => {
            if (count >= range.min && count <= range.max) {
                id = range.id;
            }
        });

        return id;
    };

    const getCategoryDescription = (): string => {
        let description = "";
        categoryRange.map((range: OptionRange) => {
            if (count >= range.min && count <= range.max) {
                description = range.description;
            }
        });
        return description;
    };

    /**
     * Set CategoryId in selectedPatient from Redux, and return categoryId, count and categoryDescription to [patientForm]
     */
    const _onSave = (): void => {
        // Set CategoryId to selectedPatient
        dispatch(setPatientCategoryId(getCategoryId()));

        props.onSave(getCategoryId(), count, getCategoryDescription());
        props.onClose();
    };

    /**
     *  Delete current information and close modal.
     */
    const _onCancel = (): void => {
        setCount(0);
        setSelectedOptionsCategory([]);
        props.onSave("", 0);
        dispatch(deletePatientCategoryId());
        dispatch(deleteCategoryList());
        props.onClose();
    };

    return (
        <>
            <Modal open={props.open} onClose={() => props.onClose()}>
                <Box className="custom-modal custom-modal--big">
                    <div className="custom-modal__title">
                        <h4>Asignación de Categoría</h4>
                        <div>
                            <h4>Categoría:</h4>
                            <p>{getCategoryLabel()}</p>
                        </div>
                    </div>
                    <div className="custom-modal__body">
                        <h3>
                            <span>Nombre del paciente:</span>{" "}
                            {selectedPatient.name}
                        </h3>
                        <div className="custom-modal__body__table">
                            {buildOptions()}
                        </div>
                        <div className="custom-modal__body__buttons">
                            <button
                                className="custom-modal__body__buttons--cancel"
                                onClick={_onCancel}
                            >
                                Cancelar
                            </button>
                            <button
                                className="custom-modal__body__buttons--accept"
                                onClick={_onSave}
                            >
                                Aceptar
                            </button>
                        </div>
                    </div>
                </Box>
            </Modal>
        </>
    );
}
