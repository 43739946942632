import { OptionCategory, OptionRange } from "../../interfaces/OptionCategory";
import { ISelectedPatient } from "../../store/interfaces";
import { URI_API, getHeaders } from "../../utils/config";
import { Box, Modal, Switch } from "@mui/material";
import {
    AppState,
    deletePatientCategoryId,
    setPatientCategoryId,
} from "../../store/reducer";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { Dispatch } from "redux";

export default function ModalNTISS(props: {
    open: boolean;
    onClose: Function;
    onSave: Function;
}) {
    const [ntissInformation, setNtissInformation] =
        useState<Map<string, OptionCategory[]>>();
    const [ntissRange, setNtissRanges] = useState<OptionRange[]>([]);

    // Current NTISS count
    const [count, setCount] = useState<number>(0);

    // Used as controller for switches
    const [selectedOptionsCategory, setSelectedOptionsCategory] = useState<
        string[]
    >([]);

    // Patient from Redux
    const selectedPatient: ISelectedPatient = useSelector(
        (state: AppState) => state.selectedPatient
    );

    const dispatch: Dispatch<any> = useDispatch();

    const getNTISSInformation = async () => {
        // Get NTISS information.
        let ntiss_res = await (
            await fetch(`${URI_API}/checklist/NTISS`, getHeaders())
        ).json();

        // Get NTISS range.
        // Two ranges: [0 - 63, 64 - 127]
        let ntiss_ranges_res = await (
            await fetch(`${URI_API}/class_ranges/patient_ntiss`, getHeaders())
        ).json();

        setNtissRanges(
            ntiss_ranges_res
                .map((ntiss_range: any) => {
                    return {
                        id: ntiss_range.id,
                        description: ntiss_range.description,
                        category: ntiss_range.category,
                        min: parseFloat(ntiss_range.minimumvalue),
                        max: parseFloat(ntiss_range.maximumvalue),
                    };
                })
                .sort((a: OptionRange, b: OptionRange) => a.min - b.min)
        );

        let ntiss_map = new Map<string, OptionCategory[]>();

        for (let ntiss of ntiss_res) {
            let option: OptionCategory = {
                category: ntiss.category,
                id: ntiss.id,
                value: ntiss.value,
                description: ntiss.description,
                subcategory: ntiss.subcategory,
                title: "",
                checked: null,
            };

            switch (option.subcategory) {
                case "respiratory":
                    option.title = "Respiratorio";
                    break;
                case "monitory":
                    option.title = "Monitoreo";
                    break;
                case "cardio":
                    option.title = "Cardiología";
                    break;
                case "vascular_access":
                    option.title = "Acceso Vascular";
                    break;
                case "nutrition":
                    option.title = "Nutrición";
                    break;
                case "transfusions":
                    option.title = "Transfusiones";
                    break;
                case "procedures":
                    option.title = "Procedimientos";
                    break;
                case "medicine":
                    option.title = "Medicina";
                    break;
            }

            if (ntiss_map.has(option.subcategory)) {
                let currentOptions: OptionCategory[] = ntiss_map.get(
                    option.subcategory
                )!;
                currentOptions.push(option);

                ntiss_map.set(option.subcategory, currentOptions);
            } else {
                ntiss_map.set(option.subcategory, [option]);
            }
        }

        setNtissInformation(ntiss_map);
    };

    useEffect(() => {
        getNTISSInformation();
    }, []);

    const toggleNtissItem = (
        itemId: string,
        value: number,
        checked: boolean
    ): void => {
        setCount(checked ? count + value : count - value);

        setSelectedOptionsCategory(
            checked
                ? [...selectedOptionsCategory, itemId]
                : selectedOptionsCategory.filter((selectedOption: string) => {
                      return selectedOption !== itemId;
                  })
        );
    };

    const getNtissId = (): string => {
        return count >= ntissRange[0].min && count <= ntissRange[0].max
            ? ntissRange[0].id
            : ntissRange[1].id;
    };

    /**
     * Set CategoryId in selectedPatient from Redux, and return categoryId, count and categoryDescription to [patientForm]
     */
    const _onSave = (): void => {
        // Set CategoryId to selectedPatient
        dispatch(setPatientCategoryId(getNtissId()));

        props.onSave(getNtissId(), count);
        props.onClose();
    };

    /**
     *  Delete current information and close modal.
     */
    const _onCancel = (): void => {
        setCount(0);
        setSelectedOptionsCategory([]);
        props.onSave("", 0);
        dispatch(deletePatientCategoryId());
        props.onClose();
    };

    const buildOptions = () => {
        if (ntissInformation === undefined) return <></>;

        return Array.from(ntissInformation).map(
            (value: [string, OptionCategory[]]) => {
                return (
                    <>
                        <div key={uuidv4()}>
                            <h3>{value[1][0].title}</h3>
                            <div className="custom-modal__body__table__line"></div>
                        </div>
                        <div className="custom-modal__body__table__options">
                            {value[1].map((value: OptionCategory) => {
                                return (
                                    <div key={uuidv4()}>
                                        <h4>{value.description}</h4>
                                        <Switch
                                            checked={selectedOptionsCategory.includes(
                                                value.id
                                            )}
                                            onChange={(_, checked) => {
                                                toggleNtissItem(
                                                    value.id,
                                                    value.value,
                                                    checked
                                                );
                                            }}
                                            color="secondary"
                                        ></Switch>
                                    </div>
                                );
                            })}
                        </div>
                    </>
                );
            }
        );
    };

    return (
        <>
            <Modal open={props.open} onClose={() => props.onClose()}>
                <Box className="custom-modal custom-modal--big">
                    <div className="custom-modal__title">
                        <h4>NTISS</h4>
                        <div>
                            <h4>Puntaje NTISS:</h4>
                            <p>{count}</p>
                        </div>
                    </div>
                    <div className="custom-modal__body">
                        <h3>
                            <span>Nombre del paciente:</span>{" "}
                            {selectedPatient.name}
                        </h3>
                        <div className="custom-modal__body__table">
                            {buildOptions()}
                        </div>
                        <div className="custom-modal__body__buttons">
                            <button
                                className="custom-modal__body__buttons--cancel"
                                onClick={_onCancel}
                            >
                                Cancelar
                            </button>
                            <button
                                className="custom-modal__body__buttons--accept"
                                onClick={_onSave}
                            >
                                Aceptar
                            </button>
                        </div>
                    </div>
                </Box>
            </Modal>
        </>
    );
}
