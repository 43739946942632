import { PlanificationContext } from "../../views/planificationView/planificationView";
import { ReactComponent as ActualizarIcon } from "../../assets/icons/guardar.svg";
import { ReactComponent as ExcelIcon } from "../../assets/icons/excel.svg";
import { useCallback, useContext, useEffect, useState } from "react";
import { FaChevronRight, FaChevronLeft } from "react-icons/fa";
import UploadArchive from "../uploadArchive/uploadArchive";
import { URI_API, getHeaders } from "../../utils/config";
import { ToastContainer, toast } from "react-toastify";
import { DayPicker } from "react-day-picker";
import { Modal, Box } from "@mui/material";
import "react-day-picker/dist/style.css";
import "./planificationCalendar.scss";
import es from "date-fns/locale/es";

export default function PlanificationCalendar() {
    const { role, availableNurses } = useContext(PlanificationContext);
    const [showModal, setShowModal] = useState(false);
    const [actualYear, setActualYear] = useState(new Date().getFullYear());
    const [januaryMonth, setJanuaryMonth] = useState<any>([]);
    const [februaryMonth, setFebruaryMonth] = useState<any>([]);
    const [marchMonth, setMarchMonth] = useState<any>([]);
    const [aprilMonth, setAprilMonth] = useState<any>([]);
    const [mayMonth, setMayMonth] = useState<any>([]);
    const [juneMonth, setJuneMonth] = useState<any>([]);
    const [julyMonth, setJulyMonth] = useState<any>([]);
    const [augustMonth, setAugustMonth] = useState<any>([]);
    const [septemberMonth, setSeptemberMonth] = useState<any>([]);
    const [octoberMonth, setOctoberMonth] = useState<any>([]);
    const [novemberMonth, setNovemberMonth] = useState<any>([]);
    const [decemberMonth, setDecemberMonth] = useState<any>([]);
    const [januaryMonthOff, setJanuaryMonthOff] = useState<any>([]);
    const [februaryMonthOff, setFebruaryMonthOff] = useState<any>([]);
    const [marchMonthOff, setMarchMonthOff] = useState<any>([]);
    const [aprilMonthOff, setAprilMonthOff] = useState<any>([]);
    const [mayMonthOff, setMayMonthOff] = useState<any>([]);
    const [juneMonthOff, setJuneMonthOff] = useState<any>([]);
    const [julyMonthOff, setJulyMonthOff] = useState<any>([]);
    const [augustMonthOff, setAugustMonthOff] = useState<any>([]);
    const [septemberMonthOff, setSeptemberMonthOff] = useState<any>([]);
    const [octoberMonthOff, setOctoberMonthOff] = useState<any>([]);
    const [novemberMonthOff, setNovemberMonthOff] = useState<any>([]);
    const [decemberMonthOff, setDecemberMonthOff] = useState<any>([]);
    const [nurseNomina, setNurseNomina] = useState<any>("");
    const [selectedNurse, setSelectedNurse] = useState<any>({});
    const [, updateState] = useState<any>();
    const [isUpdating, setIsUpdating] = useState(false);

    const dayOffModifierStyle = {
        backgroundColor: "#ac98d3",
        color: "white",
    };

    const sendChanges = () => {
        setIsUpdating(true);
        let changedDays: any[] = [];
        changedDays = changedDays.concat(
            januaryMonth,
            februaryMonth,
            marchMonth,
            aprilMonth,
            mayMonth,
            juneMonth,
            julyMonth,
            augustMonth,
            septemberMonth,
            octoberMonth,
            novemberMonth,
            decemberMonth
        );
        changedDays = changedDays.map((day) => day.toISOString().split("T")[0]);
        const payload = {
            days: changedDays,
            nomina: nurseNomina,
            workscheduleid: selectedNurse.workscheduleid,
            nurseid: selectedNurse.nurseid,
        };

        fetch(`${URI_API}/nurse/save_changes`, getHeaders("POST", payload))
            .then((res) => res.json())
            .then(() => {
                setIsUpdating(false);
                toast("Calendario Actualizado", {
                    type: "success",
                    containerId: "PC",
                });
            });
    };

    useEffect(() => {
        if (role !== "") {
            setShowModal(false);
        }
    }, [role]);

    const forceUpdate = useCallback(() => {
        updateState({});
    }, []);

    const onSelectNurse = (nomina: any) => {
        forceUpdate();
        fetch(
            `${URI_API}/nurse/schedule_info?` +
                new URLSearchParams({
                    nomina,
                }),
            getHeaders()
        )
            .then((res) => res.json())
            .then((data) => {
                setSelectedNurse(data);
                let arrAuxJanuary = [];
                let arrAuxFebruary = [];
                let arrAuxMarch = [];
                let arrAuxApril = [];
                let arrAuxMay = [];
                let arrAuxJune = [];
                let arrAuxJuly = [];
                let arrAuxAugust = [];
                let arrAuxSeptember = [];
                let arrAuxOctober = [];
                let arrAuxNovember = [];
                let arrAuxDecember = [];
                let arrAuxJanuaryOff = [];
                let arrAuxFebruaryOff = [];
                let arrAuxMarchOff = [];
                let arrAuxAprilOff = [];
                let arrAuxMayOff = [];
                let arrAuxJuneOff = [];
                let arrAuxJulyOff = [];
                let arrAuxAugustOff = [];
                let arrAuxSeptemberOff = [];
                let arrAuxOctoberOff = [];
                let arrAuxNovemberOff = [];
                let arrAuxDecemberOff = [];
                for (let fecha of data.Dias_Trabajo) {
                    let arrAux = [];
                    let aux = new Date(fecha);
                    switch (aux.getMonth()) {
                        case 0:
                            arrAuxJanuary.push(aux);
                            break;
                        case 1:
                            arrAuxFebruary.push(aux);

                            break;
                        case 2:
                            arrAuxMarch.push(aux);

                            break;
                        case 3:
                            arrAuxApril.push(aux);

                            break;
                        case 4:
                            arrAuxMay.push(aux);

                            break;
                        case 5:
                            arrAuxJune.push(aux);

                            break;
                        case 6:
                            arrAuxJuly.push(aux);
                            break;
                        case 7:
                            arrAuxAugust.push(aux);

                            break;
                        case 8:
                            arrAuxSeptember.push(aux);
                            break;
                        case 9:
                            arrAuxOctober.push(aux);
                            break;
                        case 10:
                            arrAuxNovember.push(aux);

                            break;
                        case 11:
                            arrAuxDecember.push(aux);

                            break;
                    }
                }
                setJanuaryMonth(arrAuxJanuary);
                setFebruaryMonth(arrAuxFebruary);
                setMarchMonth(arrAuxMarch);
                setAprilMonth(arrAuxApril);
                setMayMonth(arrAuxMay);
                setJuneMonth(arrAuxJune);
                setJulyMonth(arrAuxJuly);
                setAugustMonth(arrAuxAugust);
                setSeptemberMonth(arrAuxSeptember);
                setOctoberMonth(arrAuxOctober);
                setNovemberMonth(arrAuxNovember);
                setDecemberMonth(arrAuxDecember);
                for (let fecha of data.Dias_Descanso) {
                    let arrAux = [];
                    let aux = new Date(fecha);
                    switch (aux.getMonth()) {
                        case 0:
                            arrAuxJanuaryOff.push(aux);
                            break;
                        case 1:
                            arrAuxFebruaryOff.push(aux);

                            break;
                        case 2:
                            arrAuxMarchOff.push(aux);

                            break;
                        case 3:
                            arrAuxAprilOff.push(aux);

                            break;
                        case 4:
                            arrAuxMayOff.push(aux);

                            break;
                        case 5:
                            arrAuxJuneOff.push(aux);

                            break;
                        case 6:
                            arrAuxJulyOff.push(aux);
                            break;
                        case 7:
                            arrAuxAugustOff.push(aux);

                            break;
                        case 8:
                            arrAuxSeptemberOff.push(aux);
                            break;
                        case 9:
                            arrAuxOctoberOff.push(aux);
                            break;
                        case 10:
                            arrAuxNovemberOff.push(aux);
                            break;
                        case 11:
                            arrAuxDecemberOff.push(aux);
                            break;
                    }
                }
                setJanuaryMonthOff(arrAuxJanuaryOff);
                setFebruaryMonthOff(arrAuxFebruaryOff);
                setMarchMonthOff(arrAuxMarchOff);
                setAprilMonthOff(arrAuxAprilOff);
                setMayMonthOff(arrAuxMayOff);
                setJuneMonthOff(arrAuxJuneOff);
                setJulyMonthOff(arrAuxJulyOff);
                setAugustMonthOff(arrAuxAugustOff);
                setSeptemberMonthOff(arrAuxSeptemberOff);
                setOctoberMonthOff(arrAuxOctoberOff);
                setNovemberMonthOff(arrAuxNovemberOff);
                setDecemberMonthOff(arrAuxDecemberOff);
                forceUpdate();
            });
    };
    return (
        <>
            <div>
                <div
                    className="titleContainer"
                    style={{
                        paddingRight: "1.5vw",
                    }}
                >
                    <div className="monthContainer">
                        <div className="monthLabel">{actualYear}</div>
                        <button
                            className="monthButtons"
                            onClick={() => setActualYear(actualYear - 1)}
                            style={{ marginLeft: "2%" }}
                        >
                            <FaChevronLeft
                                style={{
                                    alignSelf: "center",
                                }}
                            ></FaChevronLeft>
                        </button>
                        <div className="dot"></div>
                        <button
                            className="monthButtons"
                            onClick={() => setActualYear(actualYear + 1)}
                            style={{ marginRight: "2%" }}
                        >
                            <FaChevronRight
                                style={{
                                    alignSelf: "center",
                                }}
                            ></FaChevronRight>
                        </button>
                    </div>
                    <div className="sectorContainer">
                        <button
                            onClick={() => setShowModal(true)}
                            className="changeDocumentButton"
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <ExcelIcon
                                className="iconColorOptions"
                                style={{
                                    marginRight: "0.5vw",
                                }}
                            ></ExcelIcon>
                            &nbsp;Cambiar Documento
                        </button>
                        <select
                            className="sectorSelect"
                            onChange={(e) => {
                                setNurseNomina(e.target.value);
                                onSelectNurse(e.target.value);
                            }}
                            style={{
                                marginRight: "1vw",
                            }}
                        >
                            <option value={""}></option>
                            {availableNurses.map((nurse: any) => {
                                return (
                                    <option value={nurse.key}>
                                        {nurse.text}
                                    </option>
                                );
                            })}
                        </select>
                        <button
                            className="button-actualizar-calendars"
                            style={{
                                opacity: isUpdating ? 0.4 : 1,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                            onClick={() => sendChanges()}
                        >
                            <ActualizarIcon
                                fill="white"
                                style={{
                                    marginRight: "0.5vw",
                                }}
                            ></ActualizarIcon>
                            Actualizar
                        </button>
                    </div>
                </div>
                <div className="labels-container">
                    <div className="first-label-container">
                        <div className="first-indicator"></div>
                        <div className="label">Días laborales</div>
                    </div>
                    <div className="second-label-container">
                        <div className="second-indicator"></div>
                        <div className="label">Días no laborales</div>
                    </div>
                </div>
                <div
                    style={{
                        overflow: "auto",
                        scrollbarWidth: "thin",
                        marginTop: "1.7vh",
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-around",
                            width: "100%",
                        }}
                    >
                        <DayPicker
                            mode="multiple"
                            locale={es}
                            defaultMonth={new Date(actualYear, 0)}
                            month={new Date(actualYear, 0)}
                            selected={januaryMonth}
                            onSelect={setJanuaryMonth}
                            disableNavigation
                            modifiers={{
                                dayOffModifier: januaryMonthOff,
                            }}
                            modifiersStyles={{
                                dayOffModifier: dayOffModifierStyle,
                            }}
                        ></DayPicker>
                        <DayPicker
                            locale={es}
                            mode="multiple"
                            defaultMonth={new Date(actualYear, 1)}
                            month={new Date(actualYear, 1)}
                            selected={februaryMonth}
                            disableNavigation
                            onSelect={setFebruaryMonth}
                            modifiers={{
                                dayOffModifier: februaryMonthOff,
                            }}
                            modifiersStyles={{
                                dayOffModifier: dayOffModifierStyle,
                            }}
                        ></DayPicker>
                        <DayPicker
                            locale={es}
                            mode="multiple"
                            defaultMonth={new Date(actualYear, 2)}
                            month={new Date(actualYear, 2)}
                            selected={marchMonth}
                            disableNavigation
                            onSelect={setMarchMonth}
                            modifiers={{
                                dayOffModifier: marchMonthOff,
                            }}
                            modifiersStyles={{
                                dayOffModifier: dayOffModifierStyle,
                            }}
                        ></DayPicker>
                        <DayPicker
                            locale={es}
                            mode="multiple"
                            defaultMonth={new Date(actualYear, 3)}
                            month={new Date(actualYear, 3)}
                            selected={aprilMonth}
                            disableNavigation
                            onSelect={setAprilMonth}
                            modifiers={{
                                dayOffModifier: aprilMonthOff,
                            }}
                            modifiersStyles={{
                                dayOffModifier: dayOffModifierStyle,
                            }}
                        ></DayPicker>
                    </div>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-around",
                            width: "100%",
                        }}
                    >
                        <DayPicker
                            locale={es}
                            mode="multiple"
                            defaultMonth={new Date(actualYear, 4)}
                            month={new Date(actualYear, 4)}
                            selected={mayMonth}
                            onSelect={setMayMonth}
                            disableNavigation
                            modifiers={{
                                dayOffModifier: mayMonthOff,
                            }}
                            modifiersStyles={{
                                dayOffModifier: dayOffModifierStyle,
                            }}
                        ></DayPicker>
                        <DayPicker
                            locale={es}
                            mode="multiple"
                            defaultMonth={new Date(actualYear, 5)}
                            month={new Date(actualYear, 5)}
                            selected={juneMonth}
                            onSelect={setJuneMonth}
                            disableNavigation
                            modifiers={{
                                dayOffModifier: juneMonthOff,
                            }}
                            modifiersStyles={{
                                dayOffModifier: dayOffModifierStyle,
                            }}
                        ></DayPicker>
                        <DayPicker
                            locale={es}
                            mode="multiple"
                            defaultMonth={new Date(actualYear, 6)}
                            month={new Date(actualYear, 6)}
                            selected={julyMonth}
                            onSelect={setJulyMonth}
                            disableNavigation
                            modifiers={{
                                dayOffModifier: julyMonthOff,
                            }}
                            modifiersStyles={{
                                dayOffModifier: dayOffModifierStyle,
                            }}
                        ></DayPicker>
                        <DayPicker
                            locale={es}
                            mode="multiple"
                            defaultMonth={new Date(actualYear, 7)}
                            month={new Date(actualYear, 7)}
                            selected={augustMonth}
                            onSelect={setAugustMonth}
                            disableNavigation
                            modifiers={{
                                dayOffModifier: augustMonthOff,
                            }}
                            modifiersStyles={{
                                dayOffModifier: dayOffModifierStyle,
                            }}
                        ></DayPicker>
                    </div>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-around",
                            width: "100%",
                        }}
                    >
                        <DayPicker
                            locale={es}
                            mode="multiple"
                            defaultMonth={new Date(actualYear, 8)}
                            month={new Date(actualYear, 8)}
                            selected={septemberMonth}
                            onSelect={setSeptemberMonth}
                            disableNavigation
                            modifiers={{
                                dayOffModifier: septemberMonthOff,
                            }}
                            modifiersStyles={{
                                dayOffModifier: dayOffModifierStyle,
                            }}
                        ></DayPicker>
                        <DayPicker
                            locale={es}
                            mode="multiple"
                            defaultMonth={new Date(actualYear, 9)}
                            month={new Date(actualYear, 9)}
                            selected={octoberMonth}
                            onSelect={setOctoberMonth}
                            disableNavigation
                            modifiers={{
                                dayOffModifier: octoberMonthOff,
                            }}
                            modifiersStyles={{
                                dayOffModifier: dayOffModifierStyle,
                            }}
                        ></DayPicker>
                        <DayPicker
                            locale={es}
                            mode="multiple"
                            defaultMonth={new Date(actualYear, 10)}
                            month={new Date(actualYear, 10)}
                            selected={novemberMonth}
                            onSelect={setNovemberMonth}
                            disableNavigation
                            modifiers={{
                                dayOffModifier: novemberMonthOff,
                            }}
                            modifiersStyles={{
                                dayOffModifier: dayOffModifierStyle,
                            }}
                        ></DayPicker>
                        <DayPicker
                            locale={es}
                            mode="multiple"
                            defaultMonth={new Date(actualYear, 11)}
                            month={new Date(actualYear, 11)}
                            selected={decemberMonth}
                            onSelect={setDecemberMonth}
                            disableNavigation
                            modifiers={{
                                dayOffModifier: decemberMonthOff,
                            }}
                            modifiersStyles={{
                                dayOffModifier: dayOffModifierStyle,
                            }}
                        ></DayPicker>
                    </div>
                </div>
                <Modal open={showModal} onClose={() => setShowModal(false)}>
                    <Box
                        sx={{
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%,-50%)",
                            width: "40%",
                            height: "50%",
                            bgcolor: "#FFFFFF",
                            border: "1px solid transparent",
                            boxShadow: 24,
                            p: "2%",
                            borderRadius: "32px",
                        }}
                    >
                        <UploadArchive></UploadArchive>
                    </Box>
                </Modal>
            </div>
            <ToastContainer
                containerId="PC"
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                closeOnClick
                rtl={false}
                draggable={false}
                newestOnTop={true}
            ></ToastContainer>
        </>
    );
}
