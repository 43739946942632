import { AssitanceAdjustViewContext } from "../../views/assistanceView/assistanceAdjustView";
import { useContext, useEffect, useState } from "react";
import "./addNurseModal.scss";

export default function AddNurseModal() {
    const {
        availableNurses,
        availableFloors,
        selectedNurse,
        selectedFloor,
        setSelectedFloor,
        onItemSelected,
        selectedShift,
        shifts,
    } = useContext(AssitanceAdjustViewContext);

    const [filteredFloors, setFilteredFloors] = useState([]);

    useEffect(() => {
        let currentFloors = shifts.filter(
            (shift: any) => shift.key == selectedShift.key
        )[0].floors_id;

        setFilteredFloors(
            availableFloors
                .filter((item: any) => currentFloors.includes(item["id"]))
                .map((item: any) => ({
                    key: item["id"],
                    text: `${item["floor"]}`,
                }))
        );
    }, []);

    return (
        <>
            <p className="modal-input-title">Profesional *</p>
            <select
                name="profesional"
                id="profesional"
                style={{
                    width: "100%",
                    height: "28px",
                    borderRadius: "3px",
                    backgroundColor: "#FFFFFF",
                    border: "1.5px solid #E6E6E6",
                }}
                onChange={(e: any) => {
                    onItemSelected({ id: e.target.value });
                }}
                value={selectedNurse}
            >
                <option value=""></option>
                {availableNurses.sort((a: any, b: any) => a.name.localeCompare(b.name)).map((nurse: any) => {
                    return <option value={nurse.id}>{nurse.name}</option>;
                })}
            </select>
            <p className="modal-input-title">Categoría</p>
            <input
                disabled
                value={
                    availableNurses.find((e: any) => e.id === selectedNurse)
                        ? availableNurses.find(
                              (e: any) => e.id === selectedNurse
                          ).class
                        : ""
                }
                style={{
                    height: "20px",
                    borderRadius: "3px",
                    backgroundColor: "#FFFFFF",
                    border: "1.5px solid #E6E6E6",
                }}
            ></input>
            <p className="modal-input-title">Sector *</p>
            <select
                name="sector"
                id="sector"
                style={{
                    width: "100%",
                    height: "28px",
                    borderRadius: "3px",
                    backgroundColor: "#FFFFFF",
                    border: "1.5px solid #E6E6E6",
                }}
                onChange={(e: any) => setSelectedFloor(e.target.value)}
                value={selectedFloor}
            >
                <option value=""></option>
                {filteredFloors.sort((a: any, b: any) => a.text.localeCompare(b.text)).map((floor: any) => {
                    return <option value={floor.key}>{floor.text}</option>;
                })}
            </select>
        </>
    );
}
