import { PatientViewContext } from "../../contexts/patientViewContext";
import { ILoggedUser } from "../../store/interfaces";
import { AppState } from "../../store/reducer";
import { useSelector } from "react-redux";
import { useContext } from "react";
import Avatar from "react-avatar";
import "./header.scss";

export default function HeaderComponent(props: { showSearch: boolean }) {
    const loggedUser: ILoggedUser = useSelector(
        (state: AppState) => state.loggedUser
    );

    const { setQuery, query } = useContext(PatientViewContext);

    return (
        <div className="header-container">
            <div className="header-container__search">
                {props.showSearch && (
                    <input
                        type="text"
                        name="search"
                        placeholder="Buscar por nombre, sector o habitación"
                        className="header-container__search-input"
                        value={query}
                        onChange={(e: any) => setQuery(e.target.value)}
                    ></input>
                )}
            </div>
            <div className="header-container__avatar">
                <Avatar
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%",
                    }}
                    size="41"
                    src={loggedUser?.imageURL}
                ></Avatar>
                <div className="header-container__avatar-information">
                    <div className="header-container__avatar-information-name">
                        {loggedUser?.fullName}
                    </div>
                    <div className="header-container__avatar-information-job-title">
                        {loggedUser?.job_title}
                    </div>
                </div>
            </div>
        </div>
    );
}
