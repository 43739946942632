import { Modal, Box } from "@mui/material";
import { useContext } from "react";
import { ToastContainer, toast } from "react-toastify";
import { ReactComponent as CrossIcon } from "../../assets/icons/x.svg";
import { PatientViewContext } from "../../contexts/patientViewContext";
import { URI_API, getHeaders } from "../../utils/config";
import { ISelectedPatient } from "../../store/interfaces";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";
import {
    AppState,
    deleteCategoryList,
    deletePatientCategoryId,
    deleteSelectedPatient,
} from "../../store/reducer";

export default function PatientAltaModal(props: {
    showComponent: boolean;
    closeComponent: any;
}) {
    const selectedPatient: ISelectedPatient = useSelector(
        (state: AppState) => state.selectedPatient
    );

    const altaPatient = (id: any) => {
        fetch(`${URI_API}/patients`, getHeaders("DELETE", { id }))
            .then((res) => res.json())
            .then(() => {
                toast("Paciente dado de Pre-Alta", {
                    type: "success",
                    containerId: "PAM",
                });
                props.closeComponent();
            });
    };

    return (
        <>
            <Modal open={props.showComponent} onClose={props.closeComponent}>
                <Box
                    sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%,-50%)",
                        width: "30%",
                        height: "40%",
                        bgcolor: "#FFFFFF",
                        border: "1px solid transparent",
                        boxShadow: 24,
                        p: "2%",
                        borderRadius: "32px",
                    }}
                >
                    <div className="modalTitleContainer">
                        <p className="modalTitle">Dar de Alta/Descarga</p>
                        <button
                            className="button-icon"
                            onClick={props.closeComponent}
                        >
                            <CrossIcon fill="white"></CrossIcon>
                        </button>
                    </div>
                    <div style={{ padding: "15px" }}>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                fontFamily: "Manrope",
                                fontSize: "14px",
                                fontWeight: "700",
                            }}
                        >
                            <div
                                style={{
                                    fontFamily: "Manrope",
                                    fontWeight: "400",
                                    fontSize: "14px",
                                    margin: "1%",
                                }}
                            >
                                <strong>ID: </strong>
                                {selectedPatient.attentionId}
                            </div>
                            <div
                                style={{
                                    fontFamily: "Manrope",
                                    fontWeight: "400",
                                    fontSize: "14px",
                                    margin: "1%",
                                }}
                            >
                                <strong>Nombre: </strong>
                                {selectedPatient.name}
                            </div>
                            <div
                                style={{
                                    fontFamily: "Manrope",
                                    fontWeight: "400",
                                    fontSize: "14px",
                                    margin: "1%",
                                }}
                            >
                                <strong>Fecha de Admision: </strong>
                                {selectedPatient.dateAdmission}
                            </div>
                        </div>
                    </div>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                            marginTop: "5%",
                        }}
                    >
                        <button
                            style={{
                                borderRadius: "16px",
                                border: "1px solid transparent",
                                backgroundColor: "transparent",
                                color: "#A2A2A2",
                                fontFamily: "Manrope",
                                fontWeight: "700",
                                fontSize: "12px",
                                lineHeight: "16.39px",
                                textAlign: "center",
                                padding: "1% 5% 1% 5%",
                                cursor: "pointer",
                            }}
                            type="button"
                            onClick={props.closeComponent}
                        >
                            Cancelar
                        </button>
                        <button
                            style={{
                                borderRadius: "16px",
                                border: "1px solid #E6E6E6",
                                backgroundColor: "#62098C",
                                color: "#FFFFFF",
                                fontFamily: "Manrope",
                                fontWeight: "700",
                                fontSize: "12px",
                                lineHeight: "16.39px",
                                textAlign: "center",
                                padding: "1% 5% 1% 5%",
                                cursor: "pointer",
                                marginLeft: "2%",
                            }}
                            type="button"
                            onClick={() => {
                                altaPatient(selectedPatient.id);
                            }}
                        >
                            Aceptar
                        </button>
                    </div>
                </Box>
            </Modal>
            <ToastContainer
                enableMultiContainer
                containerId={"PAM"}
                autoClose={2000}
                position="top-right"
            ></ToastContainer>
        </>
    );
}
