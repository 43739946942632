import { PlanificationContext } from "../../views/planificationView/planificationView";
import { ReactComponent as ExcelIcon } from "../../assets/icons/excel.svg";
import { CircularProgress, Modal } from "@mui/material";
import { useContext, useRef, useState } from "react";
import { URI_API } from "../../utils/config";
import "./uploadArchive.scss";

export default function UploadArchive() {
    const { setRole, setAvailableNurses } = useContext(PlanificationContext);
    const [loading, setLoading] = useState(false);
    const [lineError, setLineError] = useState<string>("");
    const fileRef = useRef<HTMLInputElement>(null);

    const [openModal, setOpenModal] = useState<boolean>(false);

    const onDragOver = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
    };

    const onDragEnter = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
    };

    const uploadExcel = async (file: any) => {
        setLoading(true);
        let formData = new FormData();
        formData.append("file", file, "file");

        fetch(`${URI_API}/process_excel`, {
            method: "POST",
            credentials: "include",
            body: formData,
        })
            .then(async (res) => {
                if (res.status !== 200) {
                    let error = await res.json();
                    throw error;
                }
                return await res.json();
            })
            .then((body) => {
                setAvailableNurses(body);
                setLoading(false);
                setRole(URL.createObjectURL(file));
            })
            .catch((error) => {
                if (error["current_line"] !== undefined)
                    setLineError(error["current_line"]);
                setOpenModal(true);
                setLoading(false);
            });
    };

    const handleDrop = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        let files = [...e.dataTransfer.files];
        if (files && files.length > 0) {
            uploadExcel(files[0]);
        }
    };
    return (
        <div
            className="upload-container"
            onDrop={(e) => handleDrop(e)}
            onDragOver={(e) => onDragOver(e)}
            onDragEnter={(e) => onDragEnter(e)}
        >
            {loading ? (
                <CircularProgress
                    style={{
                        alignSelf: "center",
                    }}
                    color="secondary"
                ></CircularProgress>
            ) : (
                <div
                    style={{
                        width: "50%",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                    }}
                >
                    <div id="upload-body">
                        <div>Arrastra aquí</div>
                        <div
                            style={{
                                marginBlock: "0.5vw",
                            }}
                        >
                            o
                        </div>
                        <button onClick={() => fileRef.current?.click()}>
                            <ExcelIcon
                                style={{
                                    fill: "#FFF",
                                    marginRight: "0.5vh",
                                    height: "5vh",
                                    width: "1.2vw",
                                }}
                            ></ExcelIcon>
                            &nbsp;Seleccionar un documento
                        </button>
                        <input
                            type="file"
                            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                            ref={fileRef}
                            multiple={false}
                            onChange={(event) => {
                                let file = event.currentTarget.files![0];
                                uploadExcel(file);
                            }}
                            hidden={true}
                        />
                    </div>
                </div>
            )}
            <Modal
                id={"modal-error"}
                open={openModal}
                onClose={() => setOpenModal(false)}
            >
                <div id="modal-error__container">
                    <div id="modal-error__container-header">
                        Algo salió mal.
                    </div>
                    <div id="modal-error__container-body">
                        <div
                            style={{
                                marginBottom:
                                    lineError.length !== 0 ? "0" : "3vh",
                            }}
                        >
                            Ha ocurrido un error. <br></br>Favor de revisar el
                            documento.
                        </div>

                        {lineError.length !== 0 && (
                            <h4>Error encontrado en la línea: {lineError}</h4>
                        )}
                        <button onClick={() => setOpenModal(false)}>
                            Aceptar
                        </button>
                    </div>
                </div>
            </Modal>
        </div>
    );
}
