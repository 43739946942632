export default class Shift {
    static AsOption(shift: any) {
        if (!shift) return null;
        return {
            key: shift.schedule_name,
            text: `${shift.schedule_name}: [${shift.entrance_time.slice(
                0,
                5
            )} - ${shift.departure_time.slice(0, 5)}]`.toUpperCase(),
            floors_id: shift.floors_id,
        };
    }

    static Current(shifts: any) {
        let today = new Date();
        const offset = today.getTimezoneOffset();
        today = new Date(today.getTime() - offset * 60 * 1000);
        let parsedDate = today.toISOString().split("T")[0];

        const current = shifts.find((s: any) => {
            let entrance_today = new Date(
                `${parsedDate}T${s.entrance_time}.${offset}`
            );
            let departure_today = new Date(
                `${parsedDate}T${s.departure_time}.${offset}`
            );

            let departure_yesterday = null;
            let entrance_yesterday = null;

            if (s.entrance_time > s.departure_time) {
                departure_today.setDate(departure_today.getDate() + 1);

                departure_yesterday = new Date(departure_today);
                departure_yesterday.setDate(departure_yesterday.getDate() - 1);

                entrance_yesterday = new Date(entrance_today);
                entrance_yesterday.setDate(entrance_yesterday.getDate() - 1);
            }

            let date = new Date();
            return (
                (date >= entrance_today && date <= departure_today) ||
                (entrance_yesterday &&
                    departure_yesterday &&
                    date >= entrance_yesterday &&
                    date <= departure_yesterday)
            );
        });

        return current || null;
    }
}

export function CurrentShift(shifts: any) {
    let today = new Date();
    const offset = today.getTimezoneOffset();
    today = new Date(today.getTime() - offset * 60 * 1000);
    let parsedDate = today.toISOString().split("T")[0];

    const current = shifts.find((s: any) => {
        let entrance_today = new Date(
            `${parsedDate}T${s.entrance_time}.${offset}`
        );
        let departure_today = new Date(
            `${parsedDate}T${s.departure_time}.${offset}`
        );

        let departure_yesterday = null;
        let entrance_yesterday = null;

        if (s.entrance_time > s.departure_time) {
            departure_today.setDate(departure_today.getDate() + 1);

            departure_yesterday = new Date(departure_today);
            departure_yesterday.setDate(departure_yesterday.getDate() - 1);

            entrance_yesterday = new Date(entrance_today);
            entrance_yesterday.setDate(entrance_yesterday.getDate() - 1);
        }

        let date = new Date();
        return (
            (date >= entrance_today && date <= departure_today) ||
            (entrance_yesterday &&
                departure_yesterday &&
                date >= entrance_yesterday &&
                date <= departure_yesterday)
        );
    });

    return current || {};
}

export function ShiftAsOption(shift: any) {
    return {
        key: shift.schedule_name,
        text: `${shift.schedule_name}: [${shift.entrance_time.slice(
            0,
            5
        )} - ${shift.departure_time.slice(0, 5)}]`.toUpperCase(),
    };
}
