import { ISelectedPatient } from "../store/interfaces";

const NursePriority = (
    shiftCount = 2,
    patient: ISelectedPatient,
    nurses: any,
    matrix: any
) => {
    // debugger
    nurses = nurses
        .map((nurse: any) => {
            // Check floorid
            // Starting Score
            let score = 35;
            //Discount 10 points per each patient
            //score = score - (nurse.patientcount * 10);
            //categorymaxvalue
            score =
                score -
                nurse.patients.reduce((agg: any, curr: any) => {
                    agg =
                        agg + 10 + Math.floor(curr.categorymaxvalue ** 2 / 100);
                    return agg;
                }, 0);

            //Get score from matrix, minimum is 5 points
            let mat = matrix.find(
                (m: any) =>
                    m.classxshort === nurse.classxshort ||
                    m.classificationid === patient.categoryId
            );
            // debugger
            score = score + (mat.score || 5);

            //If department is equal to speciality add 15 points
            //@ts-ignore
            score = score + 15 * (nurse.department === patient.specialityname);

            //Check previous nurses, if in
            let index =
                patient.nurseHistory?.findIndex(
                    (nh: any) => nh.nurseid === nurse.nurseid
                ) ?? 0;
            score = score + Math.round((index + 1) / shiftCount) * 10;

            //Minmax
            score = Math.min(Math.max(0, score), 100);

            return { ...nurse, score };
        })
        .sort((a: any, b: any) => b.score - a.score);

    return nurses;
};

export { NursePriority };
