import { IPublicClientApplication } from "@azure/msal-browser";
import LoginView from "./views/loginView/loginView";
import { Routes, Route, Navigate, Outlet } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { SideNav } from "./widgets";
import { MsalProvider } from "@azure/msal-react";
import {
    PatientView,
    AdministrationView,
    AssistanceAdjustView,
    DemandForecastView,
    PlanificationView,
    ChangeShiftView,
} from "./views";
import { useSelector } from "react-redux";
import { AppState } from "./store/reducer";

const PrivateRoutes = () => {
    const isAuthenticated: boolean = useSelector(
        (state: AppState) => state.isAuthenticated
    );

    return isAuthenticated ? (
        <>
            <SideNav />
            <Outlet />
        </>
    ) : (
        <Navigate to="/" />
    );
};

type AppProps = {
    pca: IPublicClientApplication;
};

function App({ pca }: AppProps) {
    return (
        <MsalProvider instance={pca}>
            <>
                <Routes>
                    <Route path="/" Component={LoginView} />
                    <Route element={<PrivateRoutes />}>
                        <Route path="/patient" Component={PatientView} />
                        <Route
                            path="/adjust"
                            Component={AssistanceAdjustView}
                        />
                        <Route
                            path="/planification"
                            Component={PlanificationView}
                        />
                        <Route
                            path="/forecast"
                            Component={DemandForecastView}
                        />
                        <Route
                            path="/administration"
                            Component={AdministrationView}
                        />
                        <Route
                            path="/changeShift"
                            Component={ChangeShiftView}
                        />
                    </Route>
                </Routes>
            </>
        </MsalProvider>
    );
}

export default App;
