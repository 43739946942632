import { ReactComponent as EditPatientIcon } from "../assets/icons/editPatient.svg";
import { PatientViewContext } from "../contexts/patientViewContext";
import { useEffect, useState, useContext } from "react";
import { LinearProgress, Tooltip } from "@mui/material";
import { emptySelectedPatient } from "../store/interfaces";
import { URI_API, getHeaders } from "../utils/config";
import { setSelectedPatient } from "../store/reducer";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import "./patientsTable.scss";

export default function PatientsToAssignTable(props: {
    showAssignDrawer: any;
    assignDrawer: any;
}) {
    const {
        setSelectedUnassignedPatient,
        setSelectedFloor,
        query,
        setPatients,
        patients,
    } = useContext(PatientViewContext);

    const [tableLoading, setTableLoading] = useState(true);
    const dispatch: Dispatch<any> = useDispatch();

    useEffect(() => {
        fetch(`${URI_API}/patients/unassigned`, getHeaders())
            .then((res) => res.json())
            .then((body) => {
                setPatients(body);
                setTableLoading(false);
            });
        fetch(`${URI_API}/patients/1`, getHeaders("POST", {})).then((res) =>
            res.json()
        );
    }, []);

    useEffect(() => {
        fetch(`${URI_API}/patients/unassigned`, getHeaders())
            .then((res) => res.json())
            .then((body) => {
                setPatients(body);
                setTableLoading(false);
            });
    }, [props.assignDrawer]);

    function mergeObjects(obj1: any, obj2: any): any {
        const mergedObj = { ...obj1 };

        for (const key in obj2) {
            if (obj2.hasOwnProperty(key)) {
                if (obj2[key] !== null && obj2[key] !== "") {
                    mergedObj[key] = obj2[key];
                }
            }
        }

        return mergedObj;
    }

    const selectPatient = (patient: any): void => {
        let selectedPatient: any = {
            id: patient["id"],
            name: patient["name"],
            attentionId: patient["patientid"],
            room: patient["room"],
            specialityName: patient["specialityname"],
            dateAdmission: patient["dateofadmission"],
            floorId: patient["floorid"],
            floorName: patient["floorname"],
        };
        selectedPatient = mergeObjects(emptySelectedPatient, selectedPatient);
        dispatch(setSelectedPatient(selectedPatient));
    };

    return (
        <>
            {!tableLoading && (
                <table className="mainTable">
                    {/* <tr>
                        <th className="th-first">Acciones</th>
                        <th className="th-middle">Atencion</th>
                        <th className="th-middle">Nombre del paciente</th>
                        <th className="th-last">Sector</th>
                    </tr> */}
                    {patients
                        .sort((a, b) => {
                            const stringA = a.dateofadmission
                                .split("/")
                                .reverse();
                            const stringB = b.dateofadmission
                                .split("/")
                                .reverse();
                            const fechaA = new Date(
                                parseInt(stringA[0]) > 2000
                                    ? parseInt(stringA[0])
                                    : parseInt(stringA[0]) + 2000,
                                parseInt(stringA[1]) - 1,
                                parseInt(stringA[2])
                            );
                            const fechaB = new Date(
                                parseInt(stringB[0]) > 2000
                                    ? parseInt(stringB[0])
                                    : parseInt(stringB[0]) + 2000,
                                parseInt(stringB[1]) - 1,
                                parseInt(stringB[2])
                            );
                            return fechaB.getTime() - fechaA.getTime();
                        })
                        .filter((patient) => {
                            if (query === "") {
                                return patient;
                            } else if (
                                patient.name
                                    .toUpperCase()
                                    .includes(query.toUpperCase()) ||
                                patient.room
                                    .toString()
                                    .toUpperCase()
                                    .includes(query.toUpperCase()) ||
                                patient.floorname
                                    .toUpperCase()
                                    .includes(query.toUpperCase())
                            ) {
                                return patient;
                            }
                        })
                        .map((patient) => {
                            return (
                                <tr>
                                    <td>
                                        <div
                                            style={{
                                                display: "flex",
                                            }}
                                        >
                                            <div
                                                style={{
                                                    flex: "1",
                                                }}
                                            >
                                                <Tooltip
                                                    title="Asignar paciente"
                                                    placement="top"
                                                    arrow
                                                >
                                                    <button
                                                        className="button-icon"
                                                        onClick={() => {
                                                            selectPatient(
                                                                patient
                                                            );
                                                            setSelectedUnassignedPatient(
                                                                patient
                                                            );
                                                            setSelectedFloor(
                                                                patient.floorid
                                                            );
                                                            props.showAssignDrawer();
                                                        }}
                                                    >
                                                        <EditPatientIcon></EditPatientIcon>
                                                    </button>
                                                </Tooltip>
                                            </div>
                                        </div>
                                    </td>
                                    <td>{patient.patientid}</td>
                                    <td>{patient.name}</td>
                                    <td>{patient.floorname}</td>
                                    <td>{patient.room}</td>
                                </tr>
                            );
                        })}
                </table>
            )}
            {tableLoading && (
                <LinearProgress
                    color="secondary"
                    style={{
                        marginTop: "1%",
                    }}
                ></LinearProgress>
            )}
        </>
    );
}
