import { ReactComponent as VespertinoIconSelected } from "../../assets/icons/vespertino-icon-selected.svg";
import { ReactComponent as NocturnoIconSelected } from "../../assets/icons/nocturno-icon-selected.svg";
import { ReactComponent as MatutinoIconSelected } from "../../assets/icons/matutino-icon-selected.svg";
import { AssitanceAdjustViewContext } from "../../views/assistanceView/assistanceAdjustView";
import { ReactComponent as VespertinoIcon } from "../../assets/icons/vespertino-icon.svg";
import { ReactComponent as NocturnoIcon } from "../../assets/icons/nocturno-icon.svg";
import { ReactComponent as MatutinoIcon } from "../../assets/icons/matutino-icon.svg";
import { useContext, useState } from "react";
import "./assistanceAdjustCalendar.scss";
import { motion } from "framer-motion";

export default function AssistanceAdjustCalendar(props: {
    showModal: any;
    showSideBar: boolean;
    setShowSideBar: any;
}) {
    const { calendarDays, nursesByDay, setCalendarDays, setSelectedDate } =
        useContext(AssitanceAdjustViewContext);

    const [selectedDay, setSelectedDay] = useState("");
    return (
        <div
            style={{
                display: "grid",
                gridTemplateColumns: "repeat(7,1fr)",
                gap: "16px",
                gridTemplateRows: "4vh",
                width: "100%",
                paddingInline: "1.5vw",
                paddingBottom: "2vh",
                overflow: "auto",
                scrollbarWidth: "thin",
            }}
            id="wrapper"
        >
            <td>DOM</td>
            <td>LUN</td>
            <td>MAR</td>
            <td>MIE</td>
            <td>JUE</td>
            <td>VIE</td>
            <td>SÁB</td>
            <div className="line"></div>
            {calendarDays.map((date: any, index: any) => {
                let str_date = date.day.toISOString().slice(0, 10);
                const nurseCount = nursesByDay[str_date] || [];
                let matutinoCounter = 0;
                let nocturnoCounter = 0;
                let vespertinoCounter = 0;

                nurseCount
                    .filter((e: any) => e.schedule.includes("matutino"))
                    .map((matutino: any) => {
                        matutinoCounter += matutino.value;
                    });

                nurseCount
                    .filter((e: any) => e.schedule.includes("vespertino"))
                    .map((vespertino: any) => {
                        vespertinoCounter += vespertino.value;
                    });

                nurseCount
                    .filter((e: any) => e.schedule.includes("nocturno"))
                    .map((matutino: any) => {
                        nocturnoCounter += matutino.value;
                    });

                return (
                    <motion.div
                        whileHover={{ scale: 1.1 }}
                        style={{
                            cursor: "pointer",
                            height: "150px",
                            gridColumn: date.day.getDay() + 1,
                        }}
                        onClick={() => {
                            const aux = [...calendarDays];
                            for (let e of aux) {
                                e.selected = false;
                            }
                            aux[index].selected = !aux[index].selected;
                            if (selectedDay == str_date) {
                                setSelectedDay("");
                            } else {
                                setSelectedDay(str_date);
                            }
                            setSelectedDate(aux[index].day);
                            setCalendarDays(aux);

                            if (props.showSideBar && selectedDay == str_date) {
                                props.setShowSideBar(false);
                            } else {
                                props.setShowSideBar(true);
                            }
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                width: "100%",
                                height: "100%",
                                backgroundColor:
                                    selectedDay == str_date
                                        ? "#62098C"
                                        : "#FFFFFF",
                                borderRadius: "12px",
                                boxShadow:
                                    "4px 4px 12px 2px rgba(0, 0, 0, 0.08)",
                            }}
                        >
                            <div
                                style={{
                                    flex: 1,
                                    alignSelf: "end",
                                    fontFamily: "Manrope",
                                    fontSize: "24px",
                                    margin: "8px",
                                    color:
                                        selectedDay == str_date
                                            ? "white"
                                            : "black",
                                }}
                            >
                                {date.day.toLocaleDateString("es-MX", {
                                    day: "numeric",
                                })}
                            </div>
                            <div
                                style={{
                                    flex: 2,
                                }}
                            ></div>
                            <div
                                style={{
                                    flex: 1,
                                    display: "flex",
                                    flexDirection: "column",
                                    padding: "0 0 0.5vh 0.5svw",
                                }}
                            >
                                <div
                                    style={{
                                        flexDirection: "row",
                                        display: "flex",
                                        alignItems: "center",
                                        marginBottom: "3px",
                                        color: date.selected
                                            ? "white"
                                            : "black",
                                        fontFamily: "Manrope",
                                    }}
                                >
                                    {selectedDay == str_date ? (
                                        <MatutinoIconSelected />
                                    ) : (
                                        <MatutinoIcon />
                                    )}
                                    <div
                                        style={{
                                            marginLeft: "3px",
                                            color:
                                                selectedDay == str_date
                                                    ? "white"
                                                    : "black",
                                        }}
                                    >
                                        {matutinoCounter}
                                    </div>
                                </div>
                                <div
                                    style={{
                                        flexDirection: "row",
                                        display: "flex",
                                        alignItems: "center",
                                        marginBottom: "3px",
                                        color: date.selected
                                            ? "white"
                                            : "black",
                                        fontFamily: "Manrope",
                                    }}
                                >
                                    {selectedDay == str_date ? (
                                        <VespertinoIconSelected />
                                    ) : (
                                        <VespertinoIcon />
                                    )}
                                    <div
                                        style={{
                                            marginLeft: "3px",
                                            color:
                                                selectedDay == str_date
                                                    ? "white"
                                                    : "black",
                                        }}
                                    >
                                        {vespertinoCounter}
                                    </div>
                                </div>
                                <div
                                    style={{
                                        flexDirection: "row",
                                        display: "flex",
                                        alignItems: "center",
                                        color: date.selected
                                            ? "white"
                                            : "black",
                                        fontFamily: "Manrope",
                                    }}
                                >
                                    {selectedDay == str_date ? (
                                        <NocturnoIconSelected />
                                    ) : (
                                        <NocturnoIcon />
                                    )}
                                    <div
                                        style={{
                                            marginLeft: "3px",
                                            color:
                                                selectedDay == str_date
                                                    ? "white"
                                                    : "black",
                                        }}
                                    >
                                        {nocturnoCounter}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </motion.div>
                );
            })}
        </div>
    );
}
