import { useContext, useEffect,useState } from "react";
import { ReactComponent as ReemplazarIcon } from "../../assets/icons/reemplazar-icon.svg";
import { AssitanceAdjustViewContext } from "../../views/assistanceView/assistanceAdjustView";
export default function MoveNurseModal() {
  const [actualNurse,setActualNurse]=useState<any>({})
  const {selectedNurse,nurses,availableFloors,setSelectedFloor,selectedFloor,
    selectedReason,setSelectedReason}=useContext(AssitanceAdjustViewContext)
  const changeReason = [
    {
      key:"Vacaciones",
      item:"Vacaciones"
    },
    {
      key:"Incapacidad",
      item:"Incapacidad"
    },
    {
      key:"Falta",
      item:"Falta"
    },
    {
      key:"Apoyo en área",
      item:"Apoyo en área"
    }
  ]
  //@ts-ignore
  useEffect(()=>{ 
    setActualNurse(nurses.find((e:any)=>e.id===selectedNurse))
  },[])
  return (
    <div>
      <div
        style={{
          marginTop: "2%",
          marginBottom: "1%",
          fontFamily: "Manrope",
          fontWeight: "700",
          fontSize: "12px",
        }}
      >
        Profesional
      </div>
      <input
        disabled
        style={{
          width: "100%",
          height: "20px",
          borderRadius: "3px",
          backgroundColor: "#FFFFFF",
          border: "3px solid #E6E6E6",
        }}
        value={actualNurse.nursename}
      ></input>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          marginTop: "3%",
        }}
      >
        <div
          style={{
            flex: 2,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              marginTop: "2%",
              marginBottom: "1%",
              fontFamily: "Manrope",
              fontWeight: "700",
              fontSize: "12px",
            }}
          >
            Sector Actual
          </div>
          <input
            disabled
            style={{
              width: "100%",
              height: "20px",
              borderRadius: "3px",
              backgroundColor: "#FFFFFF",
              border: "3px solid #E6E6E6",
            }}
            value={actualNurse.floor}
          ></input>
        </div>
        <div
          style={{
            flex: 1,
            flexDirection: "column",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ReemplazarIcon></ReemplazarIcon>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            flex: 2,
          }}
        >
          <div
            style={{
              marginTop: "2%",
              marginBottom: "1%",
              fontFamily: "Manrope",
              fontWeight: "700",
              fontSize: "12px",
            }}
          >
            Sector Nuevo *
          </div>
          <select
            style={{
              width: "100%",
              height: "28px",
              borderRadius: "3px",
              backgroundColor: "#FFFFFF",
              border: "3px solid #E6E6E6",
            }}
            onChange={(e)=>{
              setSelectedFloor(e.target.value)
            }}
            value={selectedFloor}
          >
            <option value=""></option>
            {availableFloors.sort((a: any, b: any) => a.floor.localeCompare(b.floor)).map((e:any)=>{
              return(
                <option value={e.id}>{e.floor}</option>
              )
            })}
          </select>
        </div>
      </div>
      <div
        style={{
          marginTop: "2%",
          marginBottom: "1%",
          fontFamily: "Manrope",
          fontWeight: "700",
          fontSize: "12px",
        }}
      >
        Razon de Cambio
      </div>
      <select
        style={{
          width: "100%",
          height: "28px",
          borderRadius: "3px",
          backgroundColor: "#FFFFFF",
          border: "3px solid #E6E6E6",
        }}
        onChange={(e:any)=>{
          setSelectedReason(e.target.value)
        }}
        value={selectedReason}
      >
        <option value=""></option>
        {changeReason.sort((a: any, b: any) => a.item.localeCompare(b.item)).map((e:any)=>{
          return(
            <option value={e.key}>{e.item}</option>
          )
        })}
      </select>
    </div>
  );
}
