const getUriEnviroment = (): string => {
    return process.env.REACT_APP_BASE_URL ?? "";
};

const getClientIdEnviroment = (): string => {
    return process.env.REACT_APP_CLIENT_ID ?? "";
};

const getAuthorityEnviroment = (): string => {
    return process.env.REACT_APP_AUTHORITY ?? "";
};

export const getHeaders = (
    method: string = "GET",
    body?: any | null
): RequestInit => {
    return {
        credentials: "include",
        method: method,
        headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Headers":
                "Origin, X-Requested-With, Content-Type, Accept",
            "Access-Control-Allow-Credentials": "true",
        },
        body: body != null ? JSON.stringify(body) : null,
    };
};

export const URI = getUriEnviroment();
export const URI_API = URI + "/api";
export const CLIENT_ID = getClientIdEnviroment();
export const AUTHORITY = getAuthorityEnviroment();
