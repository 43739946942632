import { ChangeShiftViewContext } from "../views/changeShiftView/changeShiftView";
import { NursePriority } from "../utils/nursePriority";
import { ISelectedPatient } from "../store/interfaces";
import { useContext } from "react";
import { v4 as uuid } from "uuid";
import "./patientsTable.scss";

export default function PatientShiftTable() {
    const {
        patients,
        setPatients,
        setAvailableNurses,
        shifts,
        availableNurses,
        matrix,
        query,
        changedNurses,
        setChangedNurses,
        changedSecondaryNurses,
        setChangedSecondaryNurses,
    } = useContext(ChangeShiftViewContext);

    // Order nurses by priority
    const nursePriority = (patient: ISelectedPatient, nurses: any[]) =>
        NursePriority(shifts.length, patient, [...nurses], [...matrix]).map(
            (nurse: any) => ({
                key: nurse.nurseid,
                text: `${nurse.nursename} `,
                classx: nurse.classx
            })
        );

    // Filtered nurses by floorId according the patient
    const filteredNursesByFloorId = (patient: ISelectedPatient) => {
        let filteredNurses = availableNurses.filter(
            (nurse: any) => nurse.floorId === patient.floorId
        );
        return nursePriority(patient, filteredNurses);
    };

    const selectedNurse = (nurseid: any, patient: any) => {
        let patientsaux = [...patients];
        let availableNursesaux = [...availableNurses];
        let idxPatient = patientsaux.findIndex((p) => p.id === patient.id);
        patients[idxPatient]["nurseId"] = nurseid;

        setChangedNurses({
            ...changedNurses,
            [patient.id]: nurseid,
        });

        availableNursesaux = availableNursesaux.map((n) => {
            n.patients = [];
            patientsaux.forEach((p) => {
                if (p.nurseId === n.nurseid) {
                    n.patients.push({
                        patientId: p.patientId,
                        categoryId: p.categoryId,
                        categoryMaxValue: p.categoryMaxValue,
                    });
                }
            });
            return n;
        });

        availableNursesaux.sort((a, b) => a.patientcount - b.patientcount);
        setPatients(patientsaux);
        setAvailableNurses(availableNursesaux);
    };

    const selectedSecondaryNurse = (secondarynurseid: any, patient: any) => {
        let patientsaux = [...patients];
        let availableNursesaux = [...availableNurses];
        let idxPatient = patients.findIndex((p: any) => p.id === patient.id);
        patients[idxPatient]["secondaryNurseId"] = secondarynurseid;

        setChangedSecondaryNurses({
            ...changedSecondaryNurses,
            [patient.id]: secondarynurseid,
        });

        availableNursesaux = availableNursesaux.map((n) => {
            n.patients = [];
            patientsaux.forEach((p: any) => {
                if (p.nurseId === n.secondarynurseid) {
                    n.patients.push({
                        patientId: p.patientId,
                        categoryId: p.categoryId,
                        categoryMaxValue: p.categoryMaxValue,
                    });
                }
            });
            return n;
        });
        availableNursesaux.sort(
            (a: any, b: any) => a.patientcount - b.patientcount
        );
        setPatients(patientsaux);
        setAvailableNurses(availableNursesaux);
    };

    return (
        <table className="mainTable">
            <thead>
                <tr>
                    <th className="th-first">Nombre del Paciente</th>
                    <th className="th-middle">Cuarto</th>
                    <th className="th-middle">Sector</th>
                    <th className="th-middle">Categoría</th>
                    <th className="th-middle">Especialidad</th>
                    <th className="th-middle">Profesional</th>
                    <th className="th-last">Profesional Auxiliar</th>
                </tr>
            </thead>
            <tbody>
                {patients
                    .filter((patient: any) => {
                        if (query === "") {
                            return patient;
                        } else if (
                            patient.name
                                .toUpperCase()
                                .includes(query.toUpperCase())
                        ) {
                            return patient;
                        }
                    })
                    .map((patient: any) => {
                        return (
                            <tr key={uuid()}>
                                <td className="textUppercase">
                                    {patient.name}
                                </td>
                                <td>{patient.room}</td>
                                <td className="textUppercase">
                                    {patient.floorName}
                                </td>
                                <td className="textCategoria">
                                    {patient.categoryName}
                                </td>
                                <td className="textUppercase">
                                    {patient.specialityName}
                                </td>
                                <td>
                                    <select
                                        onChange={(e: any) => {
                                            selectedNurse(
                                                e.target.value,
                                                patient
                                            );
                                        }}
                                        name=""
                                        id="profesional"
                                        className="selectProfesional"
                                        value={patient.nurseId}
                                    >
                                        <option value="">Seleccionar</option>
                                        {filteredNursesByFloorId(patient).map(
                                            (e: any) => {
                                                if (
                                                    e.classx == "ENF A" ||
                                                    e.classx == "ENF B" ||
                                                    e.classx == "ENF C" ||
                                                    e.classx == "ENF ESP" ||
                                                    e.classx == "ENF SUB" ||
                                                    e.classx == "ENF D"
                                                )
                                                return (
                                                    <option
                                                        key={uuid()}
                                                        value={e.key}
                                                    >
                                                        {e.text}
                                                    </option>
                                                );
                                            }
                                        )}
                                    </select>
                                </td>
                                <td>
                                    <select
                                        onChange={(e: any) => {
                                            selectedSecondaryNurse(
                                                e.target.value,
                                                patient
                                            );
                                        }}
                                        name=""
                                        id="profesional-auxiliar"
                                        className="selectProfesional"
                                        value={patient.secondaryNurseId}
                                    >
                                        <option value="">Seleccionar</option>
                                        {filteredNursesByFloorId(patient).map(
                                            (e: any) => {
                                                if (
                                                    e.classx == "AUX A" ||
                                                    e.classx == "AUX B" ||
                                                    e.classx == "AUX C" ||
                                                    e.classx == "AUX" ||
                                                    e.classx == "ENF AUX"
                                                )
                                                return (
                                                    <option
                                                        key={uuid()}
                                                        value={e.key}
                                                    >
                                                        {e.text}
                                                    </option>
                                                );
                                            }
                                        )}
                                    </select>
                                </td>
                            </tr>
                        );
                    })}
            </tbody>
        </table>
    );
}
