import { ReactComponent as ProfesionalsIcon } from "../../assets/icons/pros.svg";
import { ReactComponent as GuardarIcon } from "../../assets/icons/guardar.svg";
import { ReactComponent as BackIcon } from "../../assets/icons/back.svg";
import { ReactComponent as CrossIcon } from "../../assets/icons/x.svg";
import PatientShiftTable from "../../widgets/patientShiftTable";
import { useState, useEffect, createContext } from "react";
import HeaderComponent from "../../widgets/header/header";
import { ISelectedPatient } from "../../store/interfaces";
import { URI_API, getHeaders } from "../../utils/config";
import { useNavigate } from "react-router-dom";
import Shift from "../../utils/shiftFunctions";
import { Drawer } from "@mui/material";
import { v4 as uuid } from "uuid";
import "./changeShiftView.scss";

export const ChangeShiftViewContext = createContext<any>({
    patients: [],
    shifts: [],
    matrix: [],
    availableNurses: [],
    setPatients: () => {},
    setAvailableNurses: () => {},
    query: "",
    changedNurses: {},
    setChangedNurses: () => {},
    changedSecondaryNurses: {},
    setChangedSecondaryNurses: () => {},
});

export default function ChangeShiftView() {
    const [shifts, setShifts] = useState<any>([]);
    const [patients, setPatients] = useState<ISelectedPatient[]>([]);

    const [matrix, setMatrix] = useState<any>([]);
    const [floors, setFloors] = useState<any>([]);
    const [selectedFloor, setSelectedFloor] = useState<any>("");
    const [selectedShift, setSelectedShift] = useState<any>("");
    const [availableNurses, setAvailableNurses] = useState<any>([]);
    const [allPatients, setAllPatients] = useState<any>([]);
    const [query, setQuery] = useState("");

    // Used to detect new assign nurses and secondaryNurses to patients.
    const [changedNurses, setChangedNurses] = useState<any>({});
    const [changedSecondaryNurses, setChangedSecondaryNurses] = useState<any>(
        {}
    );

    // Open Modal
    const [showProfessionalModal, setShowProfessionalModal] = useState(false);
    let navigator = useNavigate();

    const canSave = () => {
        return (
            Object.keys(changedNurses).length !== 0 ||
            Object.keys(changedSecondaryNurses).length !== 0
        );
    };

    const getNursesByDay = (shift: any, floor: any) => {
        let date = new Date();
        const hours = date.getHours();
        let date_str: any = new Date(
            date.getFullYear() +
                "-" +
                (date.getMonth() + 1) +
                "-" +
                date.getDate()
        );
        if (hours >= 0 && hours < 7 && shift.key === "nocturno") {
            const day = 1000 * 60 * 60 * 24;
            const yesterday = new Date(date_str.getTime() - day);
            date_str = yesterday.toISOString().slice(0, 10);
        } else {
            date_str = date_str.toISOString().slice(0, 10);
        }
        if (!shift || !floor) {
            //alert("Hace falta un turno o sector asignado, favor de contactar a TI o Administración")
            return null;
        }
        fetch(
            `${URI_API}/nursing_calendar_sch/${date_str}/${shift.key}/${floor.key}`,
            getHeaders()
        )
            .then((res) => res.json())
            .then((res) => {
                let nurses = res.map((n: any) => ({
                    ...n,
                    get patientcount() {
                        return patients.length;
                    },
                }));
                const idList: any = [];
                nurses = nurses.reduce((agg: any, curr: any) => {
                    if (idList.indexOf(curr.nurseid) < 0) {
                        agg.push(curr);
                        idList.push(curr.nurseid);
                    }
                    return agg;
                }, []);
                setAvailableNurses(
                    nurses.sort(
                        (a: any, b: any) => a.patientcount - b.patientcount
                    )
                );
                // if (filterPatients) {
                // setPatients([...filterPatients]);
                // }
            });
    };

    const getAllWorkScheduleNames = async () => {
        let res = await (
            await fetch(`${URI_API}/work_schedule_names`, getHeaders())
        ).json();

        let currentShift = Shift.AsOption(Shift.Current(res));
        setShifts(res);
        setSelectedShift(currentShift);
        return currentShift;
    };

    const getClassCategoryMatrix = async () => {
        let res = await (
            await fetch(`${URI_API}/class_category_matrix`, getHeaders())
        ).json();
        setMatrix(res);
    };

    const getLoggedUserFloors = async () => {
        let res = await (
            await fetch(`${URI_API}/hospital_floors_per_user`, getHeaders())
        ).json();

        let availableFloors = res
            .filter((floor: any) => floor.can_update)
            .map((floor: any) => ({ key: floor.id, text: `${floor.floor}` }));
        availableFloors = [{ key: "all", text: "Todos" }, ...availableFloors];
        setFloors(availableFloors);
        setSelectedFloor(availableFloors[0]);
        return availableFloors[0];
    };

    const getPatients = async () => {
        const res = await (
            await fetch(`${URI_API}/patients`, getHeaders())
        ).json();

        const nurse_history_res = await (
            await fetch(`${URI_API}/patients/nurse_history`, getHeaders())
        ).json();

        let patients = res.sort((a: any, b: any) => a.id.localeCompare(b.id));

        patients = patients.map((p: any) => {
            let nurse_history = nurse_history_res
                .filter((pn: any) => pn.patientid === p.id)
                .sort((a: any, b: any) =>
                    a.created_date.localeCompare(b.created_date)
                );
            let patient: ISelectedPatient = {
                id: p.id,
                name: p.name,
                dateAdmission: p.dateofadmission,
                dateEgress: p.egressdate,
                room: p.room,
                categoryId: p.categoryid,
                categoryName: p.categoryname,
                categoryMaxValue: p.categorymaxvalue,
                floorId: p.floorid,
                floorName: p.floorname,
                nurseHistory: nurse_history,
                nurseId: p.nurseid,
                nurseName: p.nursename,
                attentionId: p.patientid,
                secondaryNurseId: p.secondarynurseid,
                secondaryNurseName: p.secondarynursename,
                specialityId: p.specialityid,
                specialityName: p.specialityname,
            };
            return patient;
        });

        setPatients(patients);
        setAllPatients(patients);
    };

    useEffect(() => {
        Promise.all([
            getAllWorkScheduleNames(),
            getClassCategoryMatrix(),
            getLoggedUserFloors(),
            getPatients(),
        ]).then((values: any) => {
            getNursesByDay(values[0], values[2]);
        });
    }, []);

    const onSave = () => {
        const data = patients.map((p: any) => ({
            id: p.id,
            nurseid: p.nurseId,
            secondarynurseid: p.secondaryNurseId,
        })); //agregar secondary
        fetch(`${URI_API}/patient_nurses`, getHeaders("PUT", data))
            .then((res) => res.json())
            .then((body) => {
                alert("Actividad Registrada");
            });
    };

    const dt_options: any = {
        timeZone: "America/Monterrey",
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
    };

    return (
        <ChangeShiftViewContext.Provider
            value={{
                patients,
                shifts,
                matrix,
                availableNurses,
                setPatients,
                setAvailableNurses,
                query,
                changedNurses,
                setChangedNurses,
                changedSecondaryNurses,
                setChangedSecondaryNurses,
            }}
        >
            <>
                <div style={{ marginLeft: "10%" }}>
                    <HeaderComponent showSearch={false}></HeaderComponent>
                    <div style={{ display: "flex" }}>
                        <div style={{ flex: 1 }}>
                            <div className="ParagraphText">
                                <button
                                    className="button-icon"
                                    onClick={() => navigator("/patient")}
                                >
                                    <BackIcon
                                        style={{ marginRight: "10px" }}
                                    ></BackIcon>
                                </button>
                                Cambio de turno
                            </div>
                            <div className="FridayText">
                                {new Date().toLocaleTimeString(
                                    "es-MX",
                                    dt_options
                                )}
                            </div>
                        </div>
                        <div style={{ flex: 1 }}>
                            <button
                                className="ProffesionalBtn"
                                onClick={() => setShowProfessionalModal(true)}
                            >
                                <ProfesionalsIcon
                                    style={{ marginRight: "10px" }}
                                ></ProfesionalsIcon>
                                Profesionales en turno
                            </button>
                        </div>
                    </div>
                    <div style={{ display: "flex" }}>
                        <div>
                            <input
                                type="text"
                                name="search"
                                placeholder="Buscar"
                                className="SearchInput"
                                value={query}
                                onChange={(e: any) => setQuery(e.target.value)}
                            ></input>
                        </div>
                        <div>
                            <select
                                value={selectedShift.key}
                                onChange={(e: any) => {
                                    setSelectedShift({ key: e.target.value });
                                    getNursesByDay(
                                        { key: e.target.value },
                                        selectedFloor
                                    );
                                }}
                                className="MatutinoSelect"
                            >
                                {shifts.map((shift: any) => {
                                    return (
                                        <option
                                            key={uuid()}
                                            value={shift.schedule_name}
                                        >
                                            {`${shift.schedule_name.toUpperCase()}: [${
                                                shift.entrance_time
                                            } - ${shift.departure_time}]`}
                                        </option>
                                    );
                                })}
                            </select>
                        </div>
                        <div>
                            <select
                                value={selectedFloor.key}
                                onChange={(e: any) => {
                                    let selectedFlooraux = e.target.value;
                                    let patientsaux =
                                        selectedFlooraux === "0"
                                            ? allPatients
                                            : allPatients.filter(
                                                  (p: any) =>
                                                      p.floorId ===
                                                      selectedFlooraux
                                              );

                                    if (patientsaux.length === 0) {
                                        if (selectedFloor.text !== "Todos") {
                                            alert(
                                                "No hay pacientes en este sector"
                                            );
                                        }
                                        patientsaux = allPatients;
                                        selectedFlooraux = floors[0];
                                    }

                                    setSelectedFloor({ key: e.target.value });
                                    setPatients(patientsaux);
                                    getNursesByDay(selectedShift, {
                                        key: e.target.value,
                                    });
                                }}
                                className="TodosSelect"
                            >
                                {floors
                                .sort((a: any, b: any) => a.text.localeCompare(b.text) 
                                )
                                .map((floor: any) => {
                                    return (
                                        <option key={uuid()} value={floor.key}>
                                            {floor.text}
                                        </option>
                                    );
                                })}
                            </select>
                        </div>
                        <div>
                            { canSave() &&
                            <button
                                disabled={!canSave()}
                                className="canAsignBtn"
                                onClick={() => onSave()}
                            >
                                <GuardarIcon
                                    style={{ marginRight: "10px" }}
                                ></GuardarIcon>
                                Asignar
                            </button>
                            || !canSave() &&
                            <button
                                disabled={!canSave()}
                                className="AsignBtn"
                                onClick={() => onSave()}
                            >
                                <GuardarIcon
                                    style={{ marginRight: "10px" }}
                                ></GuardarIcon>
                                Asignar
                            </button>
                            }
                        </div> 
                    </div>
                    <div
                        style={{
                            height: window.innerHeight - 200,
                            overflow: "auto",
                            scrollbarWidth: "thin",
                            marginTop: "2rem",
                        }}
                    >
                        <PatientShiftTable></PatientShiftTable>
                    </div>
                </div>

                <Drawer
                    anchor="right"
                    open={showProfessionalModal}
                    PaperProps={{
                        sx: {
                            width: "30%",
                            borderTopLeftRadius: "20px",
                        },
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            backgroundColor: "#62098C",
                            justifyContent: "end",
                            padding: "8%",
                        }}
                    >
                        <button
                            className="button-icon"
                            onClick={() => setShowProfessionalModal(false)}
                        >
                            <CrossIcon fill="white"></CrossIcon>
                        </button>
                    </div>
                    <div
                        style={{
                            display: "flex",
                            backgroundColor: "#62098C",
                            color: "#FFFFFF",
                            height: "10%",
                            alignItems: "flex-end",
                            paddingLeft: "10%",
                            paddingBottom: "10%",
                            fontFamily: "Manrope",
                            fontWeight: "700",
                            fontSize: "16px",
                            lineHeight: "21.86px",
                        }}
                    >
                        Profesionales en turno
                    </div>
                    <div
                        style={{
                            padding: "6%",
                        }}
                    >
                        <table>
                            <tr>
                                <th className="TableTitle">Profesional</th>
                                <th></th>
                                <th className="TableTitle">Categoria</th>
                            </tr>
                            {availableNurses.map((nurse: any) => {
                                return (
                                    <tr key={uuid()}>
                                        <td>{nurse.nursename}</td>
                                        <td></td>
                                        <td style={{ textAlign: "center" }}>
                                            {nurse.classx}
                                        </td>
                                    </tr>
                                );
                            })}
                        </table>
                    </div>
                </Drawer>
            </>
        </ChangeShiftViewContext.Provider>
    );
}
