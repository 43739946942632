import { Drawer, Typography } from "@mui/material";
import { ReactComponent as CrossIcon } from "../../assets/icons/x.svg";
import { ReactComponent as PatientIcon } from "../../assets/icons/patient-icon.svg";
import { useContext } from "react";
import { PatientViewContext } from "../../contexts/patientViewContext";
import { ReactComponent as ProfesionalAuxiliarIcon } from "../../assets/icons/profesional_auxiliar.svg";
import { ReactComponent as Line } from "../../assets/icons/line.svg";
import { ReactComponent as CommentPatientIcon } from "../../assets/icons/commentPatient.svg";
import "./patientActivity.scss";
import { ISelectedPatient } from "../../store/interfaces";
import { useDispatch, useSelector } from "react-redux";
import {
    AppState
} from "../../store/reducer";

export default function PatientActivity(props: {
    showComponent: boolean;
    closeComponent: any;
    openAddComment: any;
}) {
    const { patientComments } = useContext(PatientViewContext);
    const selectedPatient: ISelectedPatient = useSelector(
        (state: AppState) => state.selectedPatient
    );
    return (
        <Drawer
            anchor="right"
            open={props.showComponent}
            className="patient-activity"
            PaperProps={{
                sx: {
                    width: "30%",
                    borderTopLeftRadius: "20px",
                },
            }}
        >
            <div className="patient-activity__header">
                <button
                    className="button-icon"
                    onClick={() => props.closeComponent()}
                >
                    <CrossIcon fill="white"></CrossIcon>
                </button>
            </div>
            <div className="patient-activity__header-title">
                Actividad del paciente
            </div>
            <div className="patient-activity__body">
                <div style={{ display: "flex" }}>
                    <div
                        style={{
                            flex: 1,
                            display: "flex",
                            justifyContent: "center",
                            alignSelf: "center",
                        }}
                    >
                        <PatientIcon
                            className="SimpleIcon"
                            style={{ padding: 0 }}
                        ></PatientIcon>
                    </div>
                    <div
                        style={{
                            flex: 7,
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            height: "6vh",
                        }}
                    >
                        <div className="SimpleTittle">Profesional</div>
                        <div className="SimpleParagraph" style={{ margin: 0 }}>
                            {selectedPatient.nurseName}
                        </div>
                    </div>
                </div>
                <div style={{ display: "flex" }}>
                    <div
                        style={{
                            flex: 1,
                            display: "flex",
                            justifyContent: "center",
                            alignSelf: "center",
                        }}
                    >
                        <ProfesionalAuxiliarIcon
                            className="SimpleIcon"
                            style={{ padding: 0 }}
                        ></ProfesionalAuxiliarIcon>
                    </div>
                    <div
                        style={{
                            flex: 7,
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            height: "6vh",
                        }}
                    >
                        <div className="SimpleTittle">Profesional Auxiliar</div>
                        <div className="SimpleParagraph" style={{ margin: 0 }}>
                            {selectedPatient.secondaryNurseName}
                        </div>
                    </div>
                </div>
                <Line></Line>
                <div
                    className="SimpleTittle"
                    style={{ paddingTop: "5%", marginBottom: "12px" }}
                >
                    Movimientos y comentarios
                </div>
                {patientComments.map((e: any) => {
                    return (
                        <div style={{ display: "flex", marginBottom: "8px" }}>
                            <div style={{ flex: 1 }}>
                                <CommentPatientIcon className="SimpleIcon"></CommentPatientIcon>
                            </div>
                            <div style={{ flex: 7 }}>
                                <p className="ImportantText">
                                    {" "}
                                    <span style={{ fontWeight: 700 }}>
                                        {e.nursename}{" "}
                                    </span>{" "}
                                    comentó:
                                </p>
                                <p className="Paragraph"> {e.description}</p>
                                <p className="LittleText"> {e.time}</p>
                            </div>
                        </div>
                    );
                })}
            </div>
            <div className="patient-activity__footer">
                <button
                    onClick={() => {
                        props.openAddComment();
                    }}
                    className="patient-activity__footer-button"
                >
                    Agregar comentario
                </button>
            </div>
        </Drawer>
    );
}
