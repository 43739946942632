import './deleteNurseModal.scss'
import {useContext} from 'react'
import { AssitanceAdjustViewContext } from '../../views/assistanceView/assistanceAdjustView'
export default function DeleteNurseModal(){
    const {selectedNurse,nurses}=useContext(AssitanceAdjustViewContext)
    return(
        <div className='mainText'>
            ¿Está seguro de que desea eliminar a 
            <strong> {nurses.find((e:any)=>e.id===selectedNurse).nursename}</strong>?
        </div>
    )
}