import { ReactComponent as ReemplazarIcon } from "../../assets/icons/reemplazar-icon.svg";
import { useContext, useEffect, useState } from "react";
import { AssitanceAdjustViewContext } from "../../views/assistanceView/assistanceAdjustView";
import { toast } from "react-toastify";
export default function ReplaceNurseModal() {
    const {
        selectedNurse,
        setSelectedFloor,
        selectedFloor,
        availableFloors,
        nurses,
        availableNurses,
        replacementNurse,
        setReplacementNurse,
        selectedReason,
        setSelectedReason,
    } = useContext(AssitanceAdjustViewContext);
    const [actualNurse, setActualNurse] = useState<any>({});
    const changeReason = [
        {
            key: "Vacaciones",
            item: "Vacaciones",
        },
        {
            key: "Incapacidad",
            item: "Incapacidad",
        },
        {
            key: "Falta",
            item: "Falta",
        },
        {
            key: "Apoyo en área",
            item: "Apoyo en área",
        },
    ];

    useEffect(() => {
        setActualNurse(nurses.find((e: any) => e.id === selectedNurse));
    }, [nurses, selectedNurse]);

    const onReplacementSelected = (item: any) => {
        let isNurseDuplicated = false;
        for (let i = 0; i < nurses.length; i++) {
            if (nurses[i].nurseid === item.id) {
                isNurseDuplicated = true;
            }
        }

        if (isNurseDuplicated) {
            toast("Este profesional ya existe en el turno.", {
                type: "info",
                containerId: "AAV",
            });
        }
        setReplacementNurse(item.id);
    };
    return (
        <>
            <div>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                    }}
                >
                    <div
                        style={{
                            flex: 2,
                            display: "flex",
                            flexDirection: "column",
                        }}
                    >
                        <div
                            style={{
                                marginTop: "2%",
                                marginBottom: "1%",
                                fontFamily: "Manrope",
                                fontWeight: "700",
                                fontSize: "12px",
                            }}
                        >
                            Profesional
                        </div>
                        <input
                            disabled
                            style={{
                                width: "100%",
                                height: "20px",
                                borderRadius: "3px",
                                backgroundColor: "#FFFFFF",
                                border: "2px solid #E6E6E6",
                            }}
                            value={actualNurse.nursename}
                        ></input>
                        <div
                            style={{
                                marginTop: "10%",
                                marginBottom: "1%",
                                fontFamily: "Manrope",
                                fontWeight: "700",
                                fontSize: "12px",
                            }}
                        >
                            Categoria
                        </div>
                        <input
                            disabled
                            style={{
                                width: "100%",
                                height: "20px",
                                borderRadius: "3px",
                                backgroundColor: "#FFFFFF",
                                border: "2px solid #E6E6E6",
                            }}
                            value={actualNurse.classx}
                        ></input>
                        <div
                            style={{
                                marginTop: "10%",
                                marginBottom: "1%",
                                fontFamily: "Manrope",
                                fontWeight: "700",
                                fontSize: "12px",
                            }}
                        >
                            Sector
                        </div>
                        <input
                            disabled
                            style={{
                                width: "100%",
                                height: "20px",
                                borderRadius: "3px",
                                backgroundColor: "#FFFFFF",
                                border: "2px solid #E6E6E6",
                            }}
                            value={actualNurse.floor}
                        ></input>
                    </div>
                    <div
                        style={{
                            display: "flex",
                            flex: 1,
                            flexDirection: "column",
                            alignItems: "center",
                        }}
                    >
                        <div
                            style={{
                                flex: 2,
                                width: "2px",
                                backgroundColor: "#E0E0E0",
                            }}
                        ></div>
                        <div
                            style={{
                                flex: 1,
                                flexDirection: "column",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <ReemplazarIcon></ReemplazarIcon>
                        </div>
                        <div
                            style={{
                                flex: 2,
                                width: "2px",
                                backgroundColor: "#E0E0E0",
                            }}
                        ></div>
                    </div>
                    <div
                        style={{
                            flex: 2,
                            display: "flex",
                            flexDirection: "column",
                        }}
                    >
                        <div
                            style={{
                                marginTop: "2%",
                                marginBottom: "1%",
                                fontFamily: "Manrope",
                                fontWeight: "700",
                                fontSize: "12px",
                            }}
                        >
                            Profesional
                        </div>
                        <select
                            name="profesional"
                            id="profesional"
                            style={{
                                width: "100%",
                                height: "28px",
                                borderRadius: "3px",
                                backgroundColor: "#FFFFFF",
                                border: "2px solid #E6E6E6",
                            }}
                            onChange={(e: any) => {
                                onReplacementSelected({ id: e.target.value });
                            }}
                            value={replacementNurse}
                        >
                            <option value=""></option>
                            {availableNurses.sort((a: any, b: any) => a.name.localeCompare(b.name)).map((nurse: any) => {
                                return (
                                    <option value={nurse.id}>
                                        {nurse.name}
                                    </option>
                                );
                            })}
                        </select>
                        <div
                            style={{
                                marginTop: "10%",
                                marginBottom: "1%",
                                fontFamily: "Manrope",
                                fontWeight: "700",
                                fontSize: "12px",
                            }}
                        >
                            Categoria
                        </div>
                        <input
                            disabled
                            style={{
                                height: "20px",
                                borderRadius: "3px",
                                backgroundColor: "#FFFFFF",
                                border: "2px solid #E6E6E6",
                            }}
                            value={
                                availableNurses.find(
                                    (e: any) => e.id === replacementNurse
                                )
                                    ? availableNurses.find(
                                          (e: any) => e.id === replacementNurse
                                      ).class
                                    : ""
                            }
                        ></input>
                        <div
                            style={{
                                marginTop: "10%",
                                marginBottom: "1%",
                                fontFamily: "Manrope",
                                fontWeight: "700",
                                fontSize: "12px",
                            }}
                        >
                            Sector
                        </div>
                        <select
                            name="sector"
                            id="sector"
                            style={{
                                width: "100%",
                                height: "28px",
                                borderRadius: "3px",
                                backgroundColor: "#FFFFFF",
                                border: "2px solid #E6E6E6",
                            }}
                            onChange={(e: any) =>
                                setSelectedFloor(e.target.value)
                            }
                            value={selectedFloor}
                        >
                            <option value=""></option>
                            {availableFloors.sort((a: any, b: any) => a.floor.localeCompare(b.floor)).map((floor: any) => {
                                return (
                                    <option value={floor.id}>
                                        {floor.floor}
                                    </option>
                                );
                            })}
                        </select>
                    </div>
                </div>
                <div>
                    <div
                        style={{
                            marginTop: "2%",
                            marginBottom: "1%",
                            fontFamily: "Manrope",
                            fontWeight: "700",
                            fontSize: "12px",
                        }}
                    >
                        Razón de cambio
                    </div>
                    <select
                        style={{
                            width: "100%",
                            height: "28px",
                            borderRadius: "3px",
                            backgroundColor: "#FFFFFF",
                            border: "2px solid #E6E6E6",
                        }}
                        onChange={(e: any) => {
                            setSelectedReason(e.target.value);
                        }}
                        value={selectedReason}
                    >
                        <option value=""></option>
                        {changeReason.sort((a: any, b: any) => a.item.localeCompare(b.item)).map((e: any) => {
                            return <option value={e.key}>{e.item}</option>;
                        })}
                    </select>
                </div>
            </div>
        </>
    );
}
