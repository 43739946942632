import { ReactComponent as Ilustration } from "../../assets/images/login_nurasign.svg";
import { setLoggedUser, setAuthenticated } from "../../store/reducer";
import { Backdrop, Button, CircularProgress } from "@mui/material";
import Background from "../../assets/images/login_background.svg";
import { URI, URI_API, getHeaders } from "../../utils/config";
import { ILoggedUser } from "../../store/interfaces";
import { useMsal } from "@azure/msal-react";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import "./loginView.scss";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

export default function LoginView() {
    const { instance } = useMsal();

    const [inProgress, setInProgress] = useState<boolean>(false);

    const dispatch: Dispatch<any> = useDispatch();
    let navigator = useNavigate();

    const initializeSignIn = (): void => {
        setInProgress(true);
        instance.loginPopup().then(async (res) => {
            let res_photo: string = "";

            await Promise.all([
                // Get Token from our API
                fetch(
                    `${URI}/getAToken`,
                    getHeaders("POST", {
                        access_token: res.accessToken,
                        id_token_claims: res.idTokenClaims,
                    })
                ),

                // Get photo
                fetch("https://graph.microsoft.com/v1.0/me/photo/$value", {
                    headers: {
                        Authorization: "Bearer " + res.accessToken,
                    },
                }).then((res_photo) => res_photo.blob()),
            ]).then((result) => {
                res_photo = URL.createObjectURL(result[1]);
            });

            /// Create Session in our API
            const res_user = await fetch(
                `${URI_API}/session_user`,
                getHeaders()
            ).then((res_user) => res_user.json());

            const user: ILoggedUser = {
                id: res_user.id,
                fullName: res_user.display_name,
                job_title: res_user.job_title,
                email: res_user.upn,
                type: res_user.type,
                nurse_id: res_user.nurseid,
                floors: res_user.floors,
                imageURL: res_photo,
            };

            setInProgress(false);
            dispatch(setLoggedUser(user));
            dispatch(setAuthenticated(true));
            navigator("/adjust");
        });
    };

    return (
        <div
            id="login-background"
            style={{ backgroundImage: `url(${Background})` }}
        >
            <div id="login-modal">
                <div id="login-modal-body">
                    <img
                        src={require("../../assets/images/nurasign_color.png")}
                    ></img>
                    <div>
                        <h1>Bienvenido</h1>
                        <h3>Inicia sesión para continuar</h3>
                        <Button onClick={initializeSignIn}>
                            Iniciar sesión
                        </Button>
                    </div>
                </div>
                <div>
                    <Ilustration></Ilustration>
                </div>
            </div>
            <Backdrop
                sx={{
                    color: "#fff",
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={inProgress}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    );
}
