import { OptionCategory, OptionRange } from "../../interfaces/OptionCategory";
import { URI_API, getHeaders } from "../../utils/config";
import { ISelectedPatient } from "../../store/interfaces";
import { Box, Modal, Switch } from "@mui/material";
import {
    AppState,
    deletePatientCategoryId,
    setPatientCategoryId,
} from "../../store/reducer";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "../modalStyles.scss";
import { Dispatch } from "redux";

export default function ModalTISS(props: {
    open: boolean;
    onClose: Function;
    onSave: Function;
}) {
    const [tissInformation, setTissInformation] =
        useState<Map<string, OptionCategory[]>>();
    const [tissRange, setTissRanges] = useState<OptionRange[]>([]);

    // Current TISS count
    const [count, setCount] = useState<number>(0);

    // Used as controller for switches
    const [selectedOptionsCategory, setSelectedOptionsCategory] = useState<
        string[]
    >([]);

    // Patient from Redux
    const selectedPatient: ISelectedPatient = useSelector(
        (state: AppState) => state.selectedPatient
    );

    const dispatch: Dispatch<any> = useDispatch();

    const getTISSInformation = async () => {
        // Get TISS information.
        let tiss_res = await (
            await fetch(`${URI_API}/checklist/TISS`, getHeaders())
        ).json();

        // Get TISS range.
        // Two ranges: [0 - 44, 45 - 88]
        let tiss_ranges_res = await (
            await fetch(`${URI_API}/class_ranges/patient_tiss`, getHeaders())
        ).json();

        setTissRanges(
            tiss_ranges_res.map((tiss_range: any) => {
                return {
                    id: tiss_range.id,
                    description: tiss_range.description,
                    category: tiss_range.category,
                    min: parseFloat(tiss_range.minimumvalue),
                    max: parseFloat(tiss_range.maximumvalue),
                };
            })
        );

        let tiss_map = new Map<string, OptionCategory[]>();

        for (let tiss of tiss_res) {
            let option: OptionCategory = {
                category: tiss.category,
                id: tiss.id,
                value: tiss.value,
                description: tiss.description,
                subcategory: tiss.subcategory,
                title: "",
                checked: null,
            };

            switch (option.subcategory) {
                case "ventilatory_support":
                    option.title = "Soporte Ventilatorio";
                    break;
                case "specific_interventions":
                    option.title = "Intervenciones Específicas";
                    break;
                case "renal_support":
                    option.title = "Soporte Renal";
                    break;
                case "neuro_support":
                    option.title = "Soporte Neurológico";
                    break;
                case "metabol_support":
                    option.title = "Soporte Metabólico";
                    break;
                case "cardio_support":
                    option.title = "Soporte Cardiovascular";
                    break;
                case "basic_activities":
                    option.title = "Actividades Básicas";
                    break;
            }

            if (tiss_map.has(option.subcategory)) {
                let currentOptions: OptionCategory[] = tiss_map.get(
                    option.subcategory
                )!;
                currentOptions.push(option);

                tiss_map.set(option.subcategory, currentOptions);
            } else {
                tiss_map.set(option.subcategory, [option]);
            }
        }

        setTissInformation(tiss_map);
    };

    useEffect(() => {
        getTISSInformation();
    }, []);

    const toggleTissItem = (
        itemId: string,
        value: number,
        checked: boolean
    ) => {
        setCount(checked ? count + value : count - value);

        setSelectedOptionsCategory(
            checked
                ? [...selectedOptionsCategory, itemId]
                : selectedOptionsCategory.filter((selectedOption: string) => {
                      return selectedOption !== itemId;
                  })
        );
    };

    const getTissId = (): string => {
        return count >= tissRange[0].min && count <= tissRange[0].max
            ? tissRange[0].id
            : tissRange[1].id;
    };

    const buildOptions = (): JSX.Element | JSX.Element[] => {
        if (tissInformation === undefined) return <></>;

        return Array.from(tissInformation).map(
            (value: [string, OptionCategory[]]) => {
                return (
                    <>
                        <div>
                            <h3>{value[1][0].title}</h3>
                            <div className="custom-modal__body__table__line"></div>
                        </div>
                        <div className="custom-modal__body__table__options">
                            {value[1].map((value: OptionCategory) => {
                                return (
                                    <div>
                                        <h4>{value.description}</h4>
                                        <Switch
                                            checked={selectedOptionsCategory.includes(
                                                value.id
                                            )}
                                            onChange={(_, checked) => {
                                                toggleTissItem(
                                                    value.id,
                                                    value.value,
                                                    checked
                                                );
                                            }}
                                            color="secondary"
                                        ></Switch>
                                    </div>
                                );
                            })}
                        </div>
                    </>
                );
            }
        );
    };

    /**
     * Set CategoryId in selectedPatient from Redux, and return categoryId, count and categoryDescription to [patientForm]
     */
    const _onSave = (): void => {
        // Set CategoryId to selectedPatient
        dispatch(setPatientCategoryId(getTissId()));

        props.onSave(getTissId(), count);

        props.onClose();
    };

    /**
     *  Delete current information and close modal.
     */
    const _onCancel = (): void => {
        setCount(0);
        setSelectedOptionsCategory([]);
        props.onSave("", 0);
        dispatch(deletePatientCategoryId());
        props.onClose();
    };

    return (
        <>
            <Modal open={props.open} onClose={() => props.onClose()}>
                <Box className="custom-modal custom-modal--big">
                    <div className="custom-modal__title">
                        <h4>TISS</h4>
                        <div>
                            <h4>Puntaje TISS:</h4>
                            <p>{count}</p>
                        </div>
                    </div>
                    <div className="custom-modal__body">
                        <h3>
                            <span>Nombre del paciente:</span>{" "}
                            {selectedPatient.name}
                        </h3>
                        <div className="custom-modal__body__table">
                            {buildOptions()}
                        </div>
                        <div className="custom-modal__body__buttons">
                            <button
                                className="custom-modal__body__buttons--cancel"
                                onClick={_onCancel}
                            >
                                Cancelar
                            </button>
                            <button
                                className="custom-modal__body__buttons--accept"
                                onClick={_onSave}
                            >
                                Aceptar
                            </button>
                        </div>
                    </div>
                </Box>
            </Modal>
        </>
    );
}
